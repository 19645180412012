import { compact } from "lodash"

import { isNoteURL } from "lib/ample-util/note-url"
import { normalizeTagText, TAG_TEXT_DELIMITER, TAGS_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
const DEFAULT_FLAGS = {
  applyFilterTags: true,
  applySourceTags: false,
  isLinkingTasks: false,
};

export const INCLUDE_SOURCE_TAGS_CHARACTER = "&";
export const OMIT_FILTER_TAGS_CHARACTER = "~";
export const SHOULD_SEARCH_TASKS_CHARACTER = "-";

// --------------------------------------------------------------------------
function parseFlags(text) {
  const result = {
    ...DEFAULT_FLAGS,
    textWithoutFlags: text,
  };

  if (text.startsWith(INCLUDE_SOURCE_TAGS_CHARACTER)) {
    result.applySourceTags = true;
    result.textWithoutFlags = text.substring(INCLUDE_SOURCE_TAGS_CHARACTER.length);
  } else if (text.startsWith(OMIT_FILTER_TAGS_CHARACTER)) {
    result.applyFilterTags = false;
    result.textWithoutFlags = text.substring(OMIT_FILTER_TAGS_CHARACTER.length);
  } else if (text.startsWith(SHOULD_SEARCH_TASKS_CHARACTER)) {
    result.isLinkingTasks = true;
    result.textWithoutFlags = text.substring(SHOULD_SEARCH_TASKS_CHARACTER.length);
  }

  return result;
}

// --------------------------------------------------------------------------
// Separates a string into a set of tags and the remaining non-tag text, e.g.:
//
//    parseNoteLinkText("blah/something/and then") => {
//      applyFilterTags: false, // True if the text begins with the character that indicates user wants their suggestion to include the tags currently being filtered by UI
//      applySourceTags: false, // True if the text begins with the character that indicates user wants their suggestion to include the tags from the source note
//      isLinkingTasks: false, // True if the text begins with the character that indicates user wants to link to a task in some way
//      tags: [ "blah/something" ],
//      text: "and then"
//    }
//
export default function parseNoteLinkText(text) {
  if (isNoteURL(text)) return { ...DEFAULT_FLAGS, tags: [], text };

  const { textWithoutFlags, ...flags } = parseFlags(text);
  text = textWithoutFlags;

  const tags = [];
  const textSegments = text.split(TAG_TEXT_DELIMITER).map(textSegment => textSegment.trim());
  if (textSegments.length > 1) {
    text = textSegments[textSegments.length - 1];

    const tagsText = compact(textSegments.slice(0, -1)).join(TAG_TEXT_DELIMITER);
    if (tagsText) {
      const haveTagByTagText = {};
      tagsText.split(TAGS_DELIMITER).forEach(tagText => {
        tagText = normalizeTagText(tagText.replace(/^\/+|\/+$/g, "").trim());
        if (tagText && !haveTagByTagText[tagText]) {
          tags.push(tagText);
          haveTagByTagText[tagText] = true;
        }
      });
    }
  }

  return { ...flags, tags, text };
}
