import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import {
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText
} from "@rmwc/list"

import { SUGGESTION_TYPE } from "lib/ample-editor/components/link-target-menu/constants"
import TagIcon from "lib/ample-editor/components/tag-icon"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"

// --------------------------------------------------------------------------
export function keyFromTagSuggestion(tagSuggestion) {
  const { text } = tagSuggestion;

  return `tag-${ text }`;
}

// --------------------------------------------------------------------------
function TagSuggestion({ acceptTagSuggestion, isActiveSuggestion, tagSuggestion }) {
  const { html, text, ...tagMetadata } = tagSuggestion;

  const { shares } = tagMetadata;

  let sharedTagWarning = null;
  if (shares) {
    sharedTagWarning = (
      <ListItemSecondaryText className="shared-tag-warning">
        <i className="material-icons">error</i>
        <span className="text">Tag is shared with { shares } user{ shares === 1 ? "" : "s" }</span>
      </ListItemSecondaryText>
    );
  }

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptTagSuggestion(tagSuggestion);
    },
    [ tagSuggestion ]
  );

  return (
    <ListItem
      className="link-target-menu-item popup-list-item"
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      selected={ isActiveSuggestion }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ <TagIcon { ...tagMetadata } /> } />
      <ListItemText>
        <ListItemPrimaryText>
          { html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : text }
        </ListItemPrimaryText>

        { sharedTagWarning }
      </ListItemText>
      { isActiveSuggestion ? <ListItemMeta icon="keyboard_return" /> : null }
    </ListItem>
  );
}

// --------------------------------------------------------------------------
export default function TagSuggestions(props) {
  const {
    acceptNewTagSuggestion,
    acceptTagSuggestion,
    activeTagSuggestionIndex,
    hideTagSuggestions,
    insertContentMode,
    tagSuggestions,
  } = props;

  const onHideTagSuggestionsClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      hideTagSuggestions();
    },
    []
  );

  const onUseNewTagClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptNewTagSuggestion();
    },
    []
  );

  if (tagSuggestions === null) {
    return (
      <ListItem disabled key="loading-tags">
        <ListItemGraphic icon={ <CircularProgress size="xsmall"/> }/>
        Loading tags.
      </ListItem>
    );
  } else if (tagSuggestions.length === 0) {
    return (
      <ListItem disabled key="tags-placeholder">Type to search for a tag.</ListItem>
    );
  } else {

    return tagSuggestions.map((tagSuggestion, index) => {
      const isSelected = activeTagSuggestionIndex === index;

      switch (tagSuggestion.type) {
        case SUGGESTION_TYPE.CANCEL: {
          const icon = isSelected ? "keyboard_return" : <svg viewBox="0 0 24 24">
            <path d={ VECTOR_ICON_PATHS["keyboard-esc"] }/>
          </svg>;

          return (
            <ListItem
              className="link-target-menu-item popup-list-item"
              key="tags-cancel"
              onClick={ onHideTagSuggestionsClick }
              onMouseDown={ preventEventDefault }
              selected={ isSelected }
              tabIndex="-1"
            >
              <ListItemGraphic icon="arrow_back"/>
              <ListItemText>
                <ListItemPrimaryText>
                  <span>Search for a note to { insertContentMode ? "insert" : "link" }</span>
                </ListItemPrimaryText>
              </ListItemText>
              <ListItemMeta icon={ icon }/>
            </ListItem>
          );
        }

        case SUGGESTION_TYPE.NEW: {
          const { text } = tagSuggestion;

          return (
            <ListItem
              className="link-target-menu-item popup-list-item"
              key="tags-new"
              onClick={ onUseNewTagClick }
              onMouseDown={ preventEventDefault }
              selected={ isSelected }
              tabIndex="-1"
            >
              <ListItemGraphic icon={ <TagIcon/> }/>
              <ListItemText>
                <ListItemPrimaryText>
                  <span>Use new tag <span className="value">{ text }</span></span>
                </ListItemPrimaryText>
              </ListItemText>
              { isSelected ? <ListItemMeta icon="keyboard_return"/> : null }
            </ListItem>
          );
        }

        default:
        case SUGGESTION_TYPE.EXISTING:
          return (
            <TagSuggestion
              acceptTagSuggestion={ acceptTagSuggestion }
              isActiveSuggestion={ index === activeTagSuggestionIndex }
              key={ keyFromTagSuggestion(tagSuggestion) }
              tagSuggestion={ tagSuggestion }
            />
          );
      }
    });
  }
}

TagSuggestions.propTypes = {
  acceptNewTagSuggestion: PropTypes.func.isRequired,
  acceptTagSuggestion: PropTypes.func.isRequired,
  activeTagSuggestionIndex: PropTypes.number,
  hideTagSuggestions: PropTypes.func.isRequired,
  insertContentMode: PropTypes.bool,
  tagSuggestions: PropTypes.array,
};
