import { dequal } from "dequal/lite"

import snippetFromNoteReference from "lib/ample-editor/components/referencing-notes/snippet-from-note-reference"
import { targetFromURL } from "lib/ample-editor/lib/link-util"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import LinkView from "lib/ample-editor/views/link-view"
import { TASK_RELATION, VECTOR_ICON_NAME_BY_TASK_RELATION } from "lib/ample-util/task-url"
import { checkListItemFromTask } from "lib/ample-util/tasks"
import { amplenoteParamsFromTaskLinkNode } from "lib/ample-editor/util/is-task-link-node";

// --------------------------------------------------------------------------
const DEFAULT_VECTOR_ICON_NAME = VECTOR_ICON_NAME_BY_TASK_RELATION[TASK_RELATION.CONNECTED];

// --------------------------------------------------------------------------
function createVectorIcon() {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("class", "vector-icon");
  svg.setAttribute("viewBox", "0 0 24 24");

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", VECTOR_ICON_PATHS[DEFAULT_VECTOR_ICON_NAME]);

  svg.appendChild(path);

  return svg;
}

// --------------------------------------------------------------------------
// Styles are shared with LinkView as styling is based on the node and this is rendering
// a `link` node that LinkView would otherwise render.
export default class TaskLinkView extends LinkView {
  _lastContent = null;
  _taskContentDOM = null;

  // --------------------------------------------------------------------------
  constructor(editorView, getPos, node, decorations) {
    super(editorView, getPos, node, decorations);

    this._onTaskLinkViewClick = this._onTaskLinkViewClick.bind(this, editorView, getPos);

    this.dom.classList.add("task-link");

    // Replace LinkView's click handling with our own
    this.dom.removeEventListener("click", this._onClick);
    this.dom.addEventListener("click", this._onTaskLinkViewClick);

    this.icon.appendChild(createVectorIcon());

    // Handle all child content ourselves, so we can show a controlled preview of the task content
    this.dom.removeChild(this.contentDOM);
    this.contentDOM = null;

    this._taskContentDOM = document.createElement("span");
    this._taskContentDOM.className = "task-content";
    this.dom.appendChild(this._taskContentDOM);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  ignoreMutation(_event) {
    return true; // We'll handle all the content updates ourselves
  }

  // --------------------------------------------------------------------------
  update(editorView, getPos, node) {
    const { props: { hostApp: { fetchTask } }, state } = editorView;

    const amplenoteParams = amplenoteParamsFromTaskLinkNode(node, state, getPos);
    if (!amplenoteParams) return false;

    const { attrs: { href } } = node;

    this.dom.href = href;
    this.dom.target = targetFromURL(href);
    this.dom.setAttribute("data-href", href);

    this.icon.href = href;
    this.icon.target = this.dom.target;

    const { task: { uuid: taskUUID } } = amplenoteParams;

    const path = this.icon.querySelector("path");
    if (path) {
      const { taskParams } = amplenoteParams;
      const relation = (taskParams ? taskParams.relation : null) || TASK_RELATION.CONNECTED;

      const vectorIconName = VECTOR_ICON_NAME_BY_TASK_RELATION[relation];
      path.setAttribute("d", VECTOR_ICON_PATHS[vectorIconName]);
    }

    if (fetchTask && taskUUID) {
      fetchTask(taskUUID).then(task => {
        if (!this._destroyed) this._renderTaskContent(task);
      });
    }

    return true;
  }

  // --------------------------------------------------------------------------
  _onTaskLinkViewClick(editorView, getPos, event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    editorView.dispatch(setOpenLinkPosition(editorView.state.tr, getPos()));
  }

  // --------------------------------------------------------------------------
  _renderTaskContent(task) {
    const { content } = task;

    if (dequal(content, this._lastContent)) return;
    this._lastContent = content;

    const checkListItem = checkListItemFromTask(task);

    this._taskContentDOM.innerHTML = snippetFromNoteReference({ node: { content: checkListItem.content, type: "doc" } });
  }
}
