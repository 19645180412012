import { cloneDeep, defer } from "lodash"
import { closeHistory } from "prosemirror-history"
import { Fragment, Node, Slice } from "prosemirror-model"
import { TextSelection } from "prosemirror-state"
import { dropPoint } from "prosemirror-transform"
import React from "react"
import ReactDOM from "react-dom"
import { v4 as uuidv4 } from "uuid"

import LinkTargetMenu from "lib/ample-editor/components/link-target-menu"
import RichFootnote from "lib/ample-editor/components/rich-footnote"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { attachLinkMedia } from "lib/ample-editor/lib/file-commands"
import focusDomNodeAtEnd from "lib/ample-editor/lib/focus-dom-node-at-end"
import { removeLink } from "lib/ample-editor/lib/link-commands"
import { urlFromLinkAttributes } from "lib/ample-editor/lib/link-util"
import { parentsOffsetFromChildDom } from "lib/ample-editor/lib/popup-util"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import { getLocalFileMetadata, isLocalFileURL } from "lib/ample-editor/plugins/file-plugin"
import { descriptionEditorFindParamsFromState } from "lib/ample-editor/plugins/find-plugin"
import { registerTargetView, TARGET_VIEW_TYPE_DESCRIPTION_EDITOR } from "lib/ample-editor/plugins/target-view-plugin"
import buildEditorContext from "lib/ample-editor/util/build-editor-context"
import buildLinkTargetMenuParams from "lib/ample-editor/util/build-link-target-menu-params"
import { isTaskLinkNode } from "lib/ample-editor/util/is-task-link-node"
import mirrorTaskInNote from "lib/ample-editor/util/mirror-task-in-note"
import { updateDuplicatedNoteContent } from "lib/ample-util/note-content"
import {
  urlFromNewNoteParams,
  noteParamsFromURL,
  urlFromNoteParams,
} from "lib/ample-util/note-url"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"
import resolvePluginActionPromises from "lib/ample-util/resolve-plugin-action-promises"
import { normalizeTagText, TAG_TEXT_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
const MAX_INACTIVE_SPACER_HEIGHT = 300;
const POPUP_HORIZONTAL_MARGIN = 12;
// px below the measured position to better line up with tall content
const POPUP_TOP_OFFSET = 2;
const RICH_FOOTNOTE_CONTAINER_CLASS = "rich-footnote-container";

// --------------------------------------------------------------------------
// Determine the size and position of Rich Footnote, and return a style object representing said determination
function containerStyleFromNodePos(container, editorView, linkNodePos) {
  const { state: { selection: { head } } } = editorView;

  let userEngagementPos;
  if (editorView.editable) {
    // Make sure the cursor is actually in the link, as it's possible to open a link with the cursor elsewhere, in which
    // case we don't want to attach the container position to the cursor position
    const linkNode = editorView.state.doc.nodeAt(linkNodePos);
    if (linkNode && head >= linkNodePos && head <= linkNodePos + linkNode.nodeSize) {
      userEngagementPos = head;
    } else {
      userEngagementPos = linkNodePos;
    }
  } else {
    userEngagementPos = linkNodePos;
  }

  let userEngagementCoords;
  let linkNodeStartCoords;
  try {
    userEngagementCoords = editorView.coordsAtPos(userEngagementPos);
    linkNodeStartCoords = editorView.coordsAtPos(linkNodePos);
  } catch (_error) {
    // coordsAtPos can throw `Error: Invalid position xyz`
    return { style: null, width: null };
  }

  // This is only expected in tests, where the DOM is simulated, but we do want to be able to test the positioning
  // logic above
  if (!container.offsetParent) {
    return { style: null, width: null };
  }

  const offsetParentBounds = container.offsetParent.getBoundingClientRect();
  const editorViewBounds = editorView.dom.parentNode.getBoundingClientRect();

  const top = userEngagementCoords.bottom - offsetParentBounds.top + POPUP_TOP_OFFSET;
  const minWidth = Math.min(editorViewBounds.width - (POPUP_HORIZONTAL_MARGIN * 2), maxRichFootnotePopupWidth(editorView));
  const actualWidth = widthFromContainer(container);
  const maxWidth = Math.max(actualWidth, editorViewBounds.width - (POPUP_HORIZONTAL_MARGIN * 2));

  let left;
  const rightmostLeft = editorViewBounds.left + editorViewBounds.width - actualWidth - POPUP_HORIZONTAL_MARGIN;
  if (userEngagementCoords.top > linkNodeStartCoords.top) {
    // If the link has wrapped to a second line, we want to left-justify the link with the second line
    const { node: linkDom } = editorView.domAtPos(linkNodePos);
    const { parentsLeftOffset } = parentsOffsetFromChildDom(linkDom, RICH_FOOTNOTE_CONTAINER_CLASS);
    const linkNodeLeft = linkDom.offsetLeft + parentsLeftOffset;

    left = Math.max(
      POPUP_HORIZONTAL_MARGIN,
      Math.min(
        linkNodeStartCoords.left,
        rightmostLeft,
        linkNodeLeft + offsetParentBounds.left, // offsetParentBounds.left added because it gets subtracted from everything below, but linkNodeLeft works independently of it
      )
    );
  } else {
    left = Math.max(
      POPUP_HORIZONTAL_MARGIN,
      Math.min(
        linkNodeStartCoords.left,
        rightmostLeft
      )
    );
  }
  left = left - offsetParentBounds.left;

  return {
    style: `left: ${ left }px; top: ${ top }px; min-width: ${ minWidth }px; max-width: ${ maxWidth }px`,
    width: actualWidth,
  };
}

// --------------------------------------------------------------------------
function maxRichFootnotePopupWidth(editorView) {
  return editorView.editable ? 480 : 640;
}

// --------------------------------------------------------------------------
function renderPopupContent(component, container, editorView, pos) {
  // This needs to be _before_ we render, because the component can contain autoFocus inputs, which will scroll to
  // wherever the container is when ReachDOM.render is called (which may be where it was last shown, or the top of
  // the document)
  const { style, width } = containerStyleFromNodePos(container, editorView, pos);
  if (style) {
    container.setAttribute("style", style);

    const { props: { onPopupPositioned } } = editorView;
    if (onPopupPositioned) onPopupPositioned(container, pos);
  }

  ReactDOM.render(component, container);

  // If this was the first time the component was rendered with the viewport width, we didn't have an actual width in
  // the first call to containerStyleFromNodePos, so the RF could be mis-aligned relative to the right side of the
  // editor, in which case we need to re-calculate with the correct width
  if (widthFromContainer(container) !== width) {
    const { style: recalculatedStyle } = containerStyleFromNodePos(container, editorView, pos);
    if (recalculatedStyle) {
      container.setAttribute("style", recalculatedStyle);

      const { props: { onPopupPositioned } } = editorView;
      if (onPopupPositioned) onPopupPositioned(container, pos);
    }
  }
}

// --------------------------------------------------------------------------
function richFootnoteParams(getOpenLinkPos, editorState) {
  const nodePos = getOpenLinkPos(editorState);
  if (nodePos === null) return null;

  try {
    const node = editorState.doc.nodeAt(nodePos);

    if (!node || node.type !== editorState.schema.nodes.link) return null;

    return {
      isTaskLink: isTaskLinkNode(node, editorState, () => nodePos),
      node,
      nodePos,
    };
  } catch (_error) {
    // Likely `RangeError: Position N outside of fragment`
    return null;
  }
}

// --------------------------------------------------------------------------
function widthFromContainer(container) {
  return container.getBoundingClientRect().width;
}

// --------------------------------------------------------------------------
// This is a plugin view that provides a container to render the RF popup in. Rendering in a separate container outside
// the document provides some key benefits:
//
//  1. The content isn't in the document, so we don't need to deal with making it non-contenteditable, getting copied,
//     being affected by dom mutations, sending events up to the containing link, etc.
//
//  2. This view gets to update with the _final_ state after a change, while the Link node-views are updated _before_
//     view.state has been updated. This makes positioning in the node-view difficult because the position may not yet
//     be a valid position in the document.
//
//  3. Fixes some issues with auto-focusing inputs that are inside the link node-view (issues may have been due to
//     stopEvent propagating focus up to the document).
//
// Note that individual LinkViews handle actually rendering the React content to this container, while this container
// handles the unmounting. This requires each LinkView to provide a unique `key` property so moving directly from one
// link to another re-mounts the component (we want the constructor to run).
//
export default class LinkPluginView {
  _destroyed = false;
  _disableEditing = false;
  // Allows calling into linkPlugin without importing it (which would create a circular import)
  _linkPluginInterface = null;
  _noteLinkMenu = null;
  _noteLinkMenuContainer = null;
  _resizeTimer = null;
  _richFootnoteContainer = null;
  _richFootnoteSpacer = null;
  _updating = false;

  // --------------------------------------------------------------------------
  constructor(editorView, disableEditing, disableRichFootnoteSpacer, linkPluginInterface) {
    this._disableEditing = disableEditing;
    this._linkPluginInterface = linkPluginInterface;

    this._acceptNoteLinkSuggestion = this._acceptNoteLinkSuggestion.bind(this, editorView);
    this._insertTagText = this._insertTagText.bind(this, editorView);
    this._mirrorTask = this._mirrorTask.bind(this, editorView);
    this._onDocumentClick = this._onDocumentClick.bind(this, editorView);
    this._onNoteLinkMenuCancel = this._onNoteLinkMenuCancel.bind(this, editorView);
    this._suggestNotes = this._suggestNotes.bind(this, editorView);

    this._noteLinkMenuContainer = document.createElement("div");
    this._noteLinkMenuContainer.className = "link-target-menu-container";
    if (editorView.dom.parentNode) editorView.dom.parentNode.appendChild(this._noteLinkMenuContainer);

    this._richFootnoteContainer = document.createElement("div");
    this._richFootnoteContainer.className = RICH_FOOTNOTE_CONTAINER_CLASS;
    if (editorView.dom.parentNode) editorView.dom.parentNode.appendChild(this._richFootnoteContainer);

    if (!disableRichFootnoteSpacer) {
      this._richFootnoteSpacer = document.createElement("div");
      this._richFootnoteSpacer.className = "rich-footnote-spacer";
      if (editorView.dom.parentNode) editorView.dom.parentNode.appendChild(this._richFootnoteSpacer);
    }

    document.addEventListener("click", this._onDocumentClick, false);

    this.update(editorView, null);
  }

  // --------------------------------------------------------------------------
  destroy() {
    this._destroyed = true;

    clearTimeout(this._resizeTimer);

    document.removeEventListener("click", this._onDocumentClick, false);

    ReactDOM.unmountComponentAtNode(this._noteLinkMenuContainer);
    this._noteLinkMenuContainer.remove();

    ReactDOM.unmountComponentAtNode(this._richFootnoteContainer);
    this._richFootnoteContainer.remove();

    if (this._richFootnoteSpacer) {
      this._richFootnoteSpacer.remove();
    }
  }

  // --------------------------------------------------------------------------
  dismissNoteLinkMenu() {
    if (this._noteLinkMenu) this._noteLinkMenu.dismiss();
  }

  // --------------------------------------------------------------------------
  handleKeyDown = (editorView, event) => {
    // If the expression menu is open, we want it to show on top of the note link menu, so we can expand an expression
    // while linking to a note (e.g. `[[{today}`).
    let otherMenuOpen = false;
    editorView.someProp("isExpressionMenuOpen", isExpressionMenuOpen => {
      otherMenuOpen = isExpressionMenuOpen();
      return true;
    });
    if (otherMenuOpen) return false;

    return this._noteLinkMenu !== null ? this._noteLinkMenu.handleKeyDown(event) : false;
  }

  // --------------------------------------------------------------------------
  // "open" in this sense means visible
  isNoteLinkMenuOpen() {
    return this._noteLinkMenu && !this._noteLinkMenu.isDismissed();
  }

  // --------------------------------------------------------------------------
  update(view, _prevState) {
    this._updating = true;
    try {
      this._updateNoteLinkMenu(view);
      this._updateRichFootnote(view);
    } finally {
      this._updating = false;
    }
  }

  // --------------------------------------------------------------------------
  async _acceptNoteLinkSuggestion(view, text, href, tagTexts) {
    // We can't dispatch a new transaction when we're in an update from ProseMirror view, or it will be ignored (by
    // AmpleEditor#dispatchTransaction)
    if (this._updating) await new Promise(resolve => defer(resolve));

    const { props: { hostApp: { fetchNoteContent, linkNote } }, state, state: { schema } } = view;

    const linkTargetMenuParams = buildLinkTargetMenuParams(state, {
      cancelNoteLinkMenuPos: this._linkPluginInterface.getCancelNoteLinkMenuPos(state),
    });
    if (linkTargetMenuParams === null) return false;

    if (!text) text = linkTargetMenuParams.text.replace(/]]?$/, "").trim();

    const { startPos, endPos } = linkTargetMenuParams;
    let $endPos;
    try {
      $endPos = state.doc.resolve(endPos);
    } catch (_error) {
      // Position N out of range
      return false;
    }

    const transaction = state.tr;

    if (linkTargetMenuParams.shouldInsertContent && fetchNoteContent) {
      const fetchNoteContentResult = await fetchNoteContent(href, null);
      if (!fetchNoteContentResult) return false;

      const noteContent = cloneDeep(fetchNoteContentResult.node);
      updateDuplicatedNoteContent(noteContent);

      let fragment;
      try {
        const node = Node.fromJSON(schema, noteContent);
        fragment = node.type.name === "doc" ? node.content : Fragment.from(node);
      } catch (_error) {
        // RangeError: Invalid input for Node.fromJSON
        return false;
      }

      transaction.delete(startPos, $endPos.pos);

      const insertPos = dropPoint(transaction.doc, transaction.mapping.map($endPos.pos), new Slice(fragment, 0, 0));
      if (insertPos === null) return false;

      transaction.insert(insertPos, fragment);
      transaction.setSelection(TextSelection.create(transaction.doc, transaction.mapping.map(startPos)));
    } else {
      href = linkNote ? await linkNote(href || urlFromNewNoteParams({ name: text }), { tagTexts }) : "";

      const newNodes = [ schema.nodes.link.create({ href }, [ schema.text(text) ]) ];

      if ($endPos.end() === endPos) {
        newNodes.push(schema.text(" "));
      }

      transaction.replace(startPos, $endPos.pos, new Slice(Fragment.from(newNodes), 0, 0));
      transaction.setSelection(TextSelection.create(transaction.doc, transaction.mapping.map($endPos.pos)));
    }

    closeHistory(transaction);

    view.dispatch(transaction);
    view.focus();

    return true;
  }

  // --------------------------------------------------------------------------
  // If the menu is opened at the bottom of the editor, it may overflow the end of the editor, but because it is an
  // absolutely positioned element, the body height won't include the popup. In some browsers/environments, this will
  // lead to the popup being cut-off. To fix this, we set the height of the popup's non-absolutely-positioned parent
  // div, given knowledge of the structure being:
  //
  //   <div class="rich-footnote-container"> // react root / rich footnote plugin's this._richFootnoteContainer
  //     <RichFootnote />
  //   </div>
  //   <div class="rich-footnote-spacer" />
  //
  // This provides in-flow spacing so the body is the correct height.
  _adjustNoteSpacer = () => {
    const container = this._richFootnoteContainer;
    const spacer = this._richFootnoteSpacer;
    if (!spacer) return;

    // This appears to only happen when hot-reloading
    if (!container.parentNode) return;

    const { bottom } = container.getBoundingClientRect();
    const { bottom: editorBottom } = container.parentNode.getBoundingClientRect();

    // The spacer's current height is included in the editor bottom position
    let overflow = Math.max(0, bottom - editorBottom);

    if (spacer.dataset.height) {
      overflow = Math.max(overflow, parseInt(spacer.dataset.height, 10));
    }

    spacer.setAttribute("style", `height: ${ overflow }px;`);
    spacer.dataset.height = overflow.toString();
  };

  // --------------------------------------------------------------------------
  _attachMediaFile(view, nodePos, file) {
    const { props: { hostApp: { startMediaUpload } } } = view;
    if (!startMediaUpload) return;

    const type = (file.type && file.type.startsWith("video/")) ? "video" : "image";
    const uuid = attachLinkMedia(type, nodePos)(view.state, view.dispatch);

    startMediaUpload(uuid, file, nodePos);
  }

  // --------------------------------------------------------------------------
  _closeRichFootnote = (view, nodePos, shouldRemoveLink, placeCursorAfterLink) => {
    const { dispatch, state } = view;

    if (shouldRemoveLink) {
      removeLink(state, dispatch);
    } else if (nodePos === this._linkPluginInterface.getOpenLinkPos(state)) {
      const transaction = this._linkPluginInterface.setOpenLinkPosition(state.tr, null);

      if (placeCursorAfterLink) {
        // +1 so we're inside the link node, not the parent
        const $linkPos = transaction.doc.resolve(nodePos + 1);

        // Note that we're adding +1 here so the selection is placed _after_ the space that is inserted after all
        // link nodes (if - for some reason - the space doesn't get inserted, TextSelection.between gracefully falls
        // back to placing the cursor right at the end of the link node)
        const $afterPos = transaction.doc.resolve($linkPos.after($linkPos.depth) + 1);

        transaction.setSelection(TextSelection.between($afterPos, $afterPos, 1));
      }

      dispatch(transaction);
    }

    view.focus();
  }

  // --------------------------------------------------------------------------
  _getLinkOptionPluginActions = async (editorView, nodePos) => {
    const { props: { hostApp: { getPluginActions } } } = editorView;
    if (!getPluginActions) return [];

    const checkEditorContext = this._pluginContext(editorView, nodePos);

    const pluginActionPromises = await getPluginActions(
      [ PLUGIN_ACTION_TYPE.LINK_OPTION ],
      checkEditorContext,
      [ checkEditorContext.link ],
    );

    const pluginActions = [];

    await Promise.all(pluginActionPromises.map(async pluginActionPromise => {
      let pluginAction = null;
      try {
        pluginAction = await pluginActionPromise;
      } catch (error) {
        // We don't want to break other plugin actions, but it's useful to print to the console
        // for plugin authors to figure out they are breaking
        // eslint-disable-next-line no-console
        console.error(error);
        return;
      }
      if (pluginAction === null) return;

      const { run } = pluginAction;

      pluginActions.push({
        ...pluginAction,
        run: () => {
          const runEditorContext = this._pluginContext(editorView, nodePos);
          return run(runEditorContext, runEditorContext.link);
        },
        uuid: uuidv4(),
      });
    }));

    return pluginActions;
  };

  // --------------------------------------------------------------------------
  async _insertTagText(view, tagText, { nonTagText = "" } = {}) {
    // We can't dispatch a new transaction when we're in an update from ProseMirror view, or it will be ignored (by
    // AmpleEditor#dispatchTransaction)
    if (this._updating) await new Promise(resolve => defer(resolve));

    const { state, state: { schema } } = view;

    const linkTargetMenuParams = buildLinkTargetMenuParams(state, {
      cancelNoteLinkMenuPos: this._linkPluginInterface.getCancelNoteLinkMenuPos(state),
    });
    if (linkTargetMenuParams === null) return false;

    const { openPos, endPos } = linkTargetMenuParams;

    const transaction = state.tr;

    const textWithDelimiter = tagText.endsWith(TAG_TEXT_DELIMITER) ? tagText : (tagText + TAG_TEXT_DELIMITER);
    const newText = textWithDelimiter + nonTagText;
    transaction.replace(openPos, endPos, new Slice(Fragment.from(schema.text(newText)), 0, 0));

    closeHistory(transaction);

    view.dispatch(transaction);
    view.focus();

    return true;
  }

  // --------------------------------------------------------------------------
  _mirrorTask(editorView, noteURL) {
    const linkTargetMenuParams = buildLinkTargetMenuParams(editorView.state, {
      cancelNoteLinkMenuPos: this._linkPluginInterface.getCancelNoteLinkMenuPos(editorView.state),
    });
    if (linkTargetMenuParams === null) return false;

    const { endPos, inCheckListItem, startPos } = linkTargetMenuParams;
    if (!inCheckListItem) return false;

    return mirrorTaskInNote(editorView, startPos, endPos, noteURL);
  }

  // --------------------------------------------------------------------------
  _onDocumentClick(view, event) {
    if (this._richFootnoteSpacer && event.target === this._richFootnoteSpacer && view.editable) {
      focusDomNodeAtEnd(view.dom);
    }

    if (this._richFootnoteContainer && !this._richFootnoteContainer.contains(event.target)) {
      const linkPos = this._linkPluginInterface.getOpenLinkPos(view.state);
      if (view.editable && linkPos !== -1) return;
      if (linkPos !== null) view.dispatch(this._linkPluginInterface.setOpenLinkPosition(view.state.tr, null));
    }
  }

  // --------------------------------------------------------------------------
  _onNoteLinkMenuCancel(editorView) {
    const { dispatch, state } = editorView;

    const linkTargetMenuParams = buildLinkTargetMenuParams(state);
    if (linkTargetMenuParams !== null) {
      const { startPos } = linkTargetMenuParams;
      dispatch(this._linkPluginInterface.setCancelNoteLinkMenuPos(state.tr, startPos));
    }
  }

  // --------------------------------------------------------------------------
  _pluginContext(editorView, nodePos) {
    const getSelectionRange = () => {
      const { state: { doc, schema } } = editorView;

      const node = doc.nodeAt(nodePos);
      if (!node || node.type !== schema.nodes.link) return null;

      // buildEditorContext expects our position to be _in_ the link
      const linkStartPos = nodePos + 1;

      return { from: linkStartPos, head: linkStartPos, to: nodePos + node.nodeSize };
    };

    return buildEditorContext(editorView, getSelectionRange, getSelectionRange() || {});
  }

  // --------------------------------------------------------------------------
  _registerEditorView = (topLevelEditorView, descriptionEditorView) => {
    const { dispatch, state } = topLevelEditorView;
    return registerTargetView(topLevelEditorView, descriptionEditorView, TARGET_VIEW_TYPE_DESCRIPTION_EDITOR)(state, dispatch);
  };

  // --------------------------------------------------------------------------
  _resizeRichFootnotePopup(editorView, pos) {
    if (!this._richFootnoteContainer) return;

    const { style, width } = containerStyleFromNodePos(this._richFootnoteContainer, editorView, pos);
    if (style) {
      this._richFootnoteContainer.setAttribute("style", style);

      const { props: { onPopupPositioned } } = editorView;
      if (onPopupPositioned) onPopupPositioned(this._richFootnoteContainer, pos);
    }

    // The RF popup can contain content that grows horizontally and isn't loaded on the initial render, in which case
    // it can render wider than we thought it would when we adjust the positioning above, but this is a incremental
    // size increase that will repeat until it's the max size (for reasons unclear as of 11/2020). Otherwise, we'll
    // keep checking for resizing to handle the window being resized or moved in a way that mis-aligns the popup.
    const delay = width !== widthFromContainer(this._richFootnoteContainer) ? 1 : 500;
    this._resizeTimer = setTimeout(this._resizeRichFootnotePopup.bind(this, editorView, pos), delay);
  }

  // --------------------------------------------------------------------------
  _runLinkTargetPluginActions = async (editorView, nodePos, pluginParams) => {
    const { props: { hostApp: { getPluginActions } } } = editorView;
    if (!getPluginActions) return [];

    const editorContext = this._pluginContext(editorView, nodePos);

    getPluginActions(
      [ PLUGIN_ACTION_TYPE.LINK_TARGET ],
      editorContext,
      pluginParams.args,
      { onlyPluginUUID: pluginParams.uuid },
    ).then(pluginActionPromises => resolvePluginActionPromises(pluginActionPromises, {
      eachPluginAction: ({ run }) => {
        run(this._pluginContext(editorView, nodePos), pluginParams.args);
      },
      shouldCancel: () => this._destroyed,
    }));
  };

  // --------------------------------------------------------------------------
  _selectMedia = async (editorView, nodePos) => {
    const { props: { hostApp: { selectMedia } } } = editorView;
    await selectMedia(contentType => {
      const { dispatch, state } = editorView;
      const type = (contentType && contentType.startsWith("video/")) ? "video" : "image";
      return attachLinkMedia(type, nodePos)(state, dispatch);
    });
  };

  // --------------------------------------------------------------------------
  _setLinkAttributes(view, nodePos, changes) {
    const { dispatch, state: { doc, schema } } = view;

    const transform = view.state.tr;

    if (changes !== null) {
      const node = doc.nodeAt(nodePos);
      if (!node || node.type !== schema.nodes.link) return;
      transform.setNodeMarkup(nodePos, null, { ...node.attrs, ...changes });
    } else {
      // This is a special case that allows the RichFootnote to refresh the toolbar - or other plugin - as the
      // target editor view has changed
      transform.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);
    }

    dispatch(transform);
  }

  // --------------------------------------------------------------------------
  _setLinkText = (view, nodePos, text) => {
    const { dispatch, state: { doc, schema } } = view;

    const node = doc.nodeAt(nodePos);
    if (!node || node.type !== schema.nodes.link) return;

    const transform = view.state.tr;
    transform.insertText(text, nodePos + 1, nodePos + node.nodeSize - 1);

    // If they've deleted all the text in the link, we'll give them a break and keep the popup open
    if (text.length === 0) this._linkPluginInterface.setIsNewLink(transform, true);

    dispatch(transform);
  };

  // --------------------------------------------------------------------------
  _setNoteLinkMenu = noteLinkMenu => {
    this._noteLinkMenu = noteLinkMenu;
  };

  // --------------------------------------------------------------------------
  _suggestNotes = async (view, queryText, queryTagTexts) => {
    const { props: { hostApp: { suggestNotes, suggestTags } } } = view;

    // In tests suggestNotes may not be available
    if (!suggestNotes) return { notes: [], queryTagByText: {} };

    queryText = queryText.trim();

    let allowPrefixMatch = true;
    if (queryText.endsWith("]")) {
      allowPrefixMatch = false;
      queryText = queryText.replace(/]]?$/, "").trim();
    }

    if (queryText.length === 0) return { notes: [], queryTagByText: {} };

    const result = await suggestNotes(queryText);

    queryTagTexts = (queryTagTexts || []).map(tagText => normalizeTagText(tagText));

    const queryTagByText = {};

    for (let i = 0; i < queryTagTexts.length; i++) {
      const queryTagText = queryTagTexts[i];

      let queryTag = null;

      // This is fairly heavy-weight (round-tripping to the outer application for every query tag), but we don't
      // typically anticipate having more than one or two query tags and we want to have the relevant metadata.
      if (suggestTags) {
        // eslint-disable-next-line no-await-in-loop
        const tagSuggestions = await suggestTags(queryTagText);
        queryTag = tagSuggestions.find(({ text }) => text === queryTagText)
      }

      queryTagByText[queryTagText] = queryTag || { text: queryTagText };
    }

    // If we can, we want to separate out a default option that is a really good match for the search term, since
    // it will be presented as the default for auto-completion.
    let matchingNoteSuggestion = null;

    // We're using `normalizeTagText` here just to get matching of pretty-much-the-same note names, e.g.
    // "Blah great note" vs "blah  GREAT-note"
    const normalizedText = normalizeTagText(queryText);

    const queryNoteParams = noteParamsFromURL(queryText);
    const normalizedQueryNoteURL = queryNoteParams ? urlFromNoteParams(queryNoteParams) : null;

    const noteSuggestions = [];
    result.notes.forEach(note => {
      const { name } = note;

      if (matchingNoteSuggestion === null) {
        const normalizedName = normalizeTagText(name);
        const isMatchingName = allowPrefixMatch
          ? normalizedName.startsWith(normalizedText)
          : normalizedName === normalizedText;


        const hasMatchingTag = queryTagTexts.length === 0 ||
          (note.tags || []).find(({ text }) => text in queryTagByText);

        const isMatchingURL = normalizedQueryNoteURL && note.url === normalizedQueryNoteURL;

        if ((isMatchingName && hasMatchingTag) || isMatchingURL) {
          matchingNoteSuggestion = note;
          return;
        }
      }

      noteSuggestions.push(note);
    });

    if (!matchingNoteSuggestion) {
      matchingNoteSuggestion = { name: queryText, tags: Object.values(queryTagByText) };
    }

    noteSuggestions.unshift(matchingNoteSuggestion);

    return { ...result, notes: noteSuggestions.slice(0, 8), queryTagByText };
  };

  // --------------------------------------------------------------------------
  _shrinkSpacer() {
    if (!this._richFootnoteSpacer) return;

    // To avoid the height of the document flipping back and forth when opening a RF, we'll leave the spacer expanded
    // (up to a reasonable amount) when the RF closes. In some cases this is not desirable, so it can be disabled.
    let height = this._richFootnoteSpacer.dataset.height;
    if (height) height = Math.min(MAX_INACTIVE_SPACER_HEIGHT, parseInt(height, 10));

    this._richFootnoteSpacer.setAttribute("style", `height: ${ height ? `${ height }px` : 0 };`);
    this._richFootnoteSpacer.dataset.height = (height || 0).toString();
  }

  // --------------------------------------------------------------------------
  _updateNoteLinkMenu(view) {
    const { state } = view;

    const linkTargetMenuParams = buildLinkTargetMenuParams(state, {
      cancelNoteLinkMenuPos: this._linkPluginInterface.getCancelNoteLinkMenuPos(state),
    });

    if (linkTargetMenuParams !== null) {
      // eslint-disable-next-line no-unused-vars
      const { inCheckListItem, openPos, text } = linkTargetMenuParams;
      const { props: { hostApp: { fetchNoteContent, suggestTags, suggestTasks } } } = view;

      renderPopupContent(
        <LinkTargetMenu
          acceptSuggestion={ this._acceptNoteLinkSuggestion }
          acceptSuggestionOnTab
          editorView={ view }
          fetchNoteContent={ fetchNoteContent }
          // inCheckListItem={ inCheckListItem } // TEMP disabled while feature is completed
          initialOptions={ this._linkPluginInterface.getNoteLinkMenuOptions(state, openPos) }
          insertContentMode={ linkTargetMenuParams.shouldInsertContent && !!fetchNoteContent }
          insertTagText={ this._insertTagText }
          key={ openPos }
          mirrorTask={ this._mirrorTask }
          onCancel={ this._onNoteLinkMenuCancel }
          ref={ this._setNoteLinkMenu }
          suggestNotes={ this._suggestNotes }
          suggestTags={ suggestTags }
          suggestTasks={ suggestTasks }
          text={ text }
        />,
        this._noteLinkMenuContainer,
        view,
        openPos
      );
    } else {
      ReactDOM.unmountComponentAtNode(this._noteLinkMenuContainer);
    }
  }

  // --------------------------------------------------------------------------
  _updateRichFootnote(editorView) {
    const { state } = editorView;

    clearTimeout(this._resizeTimer);

    const params = richFootnoteParams(this._linkPluginInterface.getOpenLinkPos, state);
    if (params === null) {
      ReactDOM.unmountComponentAtNode(this._richFootnoteContainer);
      this._shrinkSpacer();
      return;
    }

    const { isTaskLink, node, nodePos } = params;
    if (isTaskLink) {
      // TODO: render task link specific footnote
    } else {
      const { attrs, attrs: { description, href, media } } = node;

      const mediaURL = urlFromLinkAttributes(attrs);

      let localMediaURL = mediaURL;
      if (isLocalFileURL(mediaURL)) {
        const localFileMetadata = getLocalFileMetadata(state, mediaURL);
        if (localFileMetadata) localMediaURL = localFileMetadata.url;
      }

      const {
        currentIndex: findIndex,
        imageTextHasMatch,
        imageTextIsCurrentMatch,
        query: findQuery,
      } = descriptionEditorFindParamsFromState(state, nodePos);

      const { props: { hostApp, hostApp: { selectMedia, startMediaUpload } } } = editorView;

      renderPopupContent(
        <HostAppContext.Provider value={ hostApp }>
          <RichFootnote
            // This is required so we re-initialize the component if switching from one link to a different link
            // without having no link selected in between (which would unmount the component first).
            key={ nodePos }

            // Node properties
            description={ description }
            href={ href }
            media={ media }
            text={ node.textContent }

            localMediaURL={ localMediaURL }

            // Callbacks
            attachMediaFile={ startMediaUpload ? this._attachMediaFile.bind(this, editorView, nodePos) : null }
            getLinkOptionPluginActions={ this._getLinkOptionPluginActions.bind(this, editorView, nodePos) }
            onAttrChange={ this._setLinkAttributes.bind(this, editorView, nodePos) }
            onClose={ this._closeRichFootnote.bind(this, editorView, nodePos) }
            onTextChange={ this._setLinkText.bind(this, editorView, nodePos) }
            registerEditorView={ this._registerEditorView.bind(this, editorView) }
            runLinkTargetPluginActions={ this._runLinkTargetPluginActions.bind(this, editorView, nodePos) }
            selectMedia={ selectMedia ? this._selectMedia.bind(this, editorView, nodePos) : null }
            suggestNotes={ this._suggestNotes }

            // Settings
            adjustSpacer={ this._adjustNoteSpacer }
            allowEditing={ editorView.editable && !this._disableEditing }
            editFieldName={ this._linkPluginInterface.getEditFieldName(state) }
            imageTextHasMatch={ imageTextHasMatch }
            imageTextIsCurrentMatch={ imageTextIsCurrentMatch }
            initialAllowAutoFocus={ editorView.hasFocus() }
            initialHasContent={ node.nodeSize > 2 }
            initialIsNewLink={ this._linkPluginInterface.getIsNewLink(state) }
            findIndex={ findIndex }
            findQuery={ findQuery }
          />
        </HostAppContext.Provider>,
        this._richFootnoteContainer,
        editorView,
        nodePos
      );
    }

    this._resizeTimer = setTimeout(this._resizeRichFootnotePopup.bind(this, editorView, nodePos), 1);
  }
}
