/* eslint-disable */

import { format, subDays } from "date-fns"
import jsonpack from "jsonpack"
import { cloneDeep, omit } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import applyDevTools from "prosemirror-dev-tools"
import { Node } from "prosemirror-model"
import { v4 as uuidv4 } from "uuid"

import { openAttachment, uploadAttachment, uploadMedia } from "api"
import FindInEditor from "find-in-editor"
import AmpleEditor from "lib/ample-editor/components/ample-editor"
import { probeImage } from "lib/ample-editor/lib/image-util"
import NOTE_CONTENT_TYPE from "lib/ample-editor/lib/note-content-type"
import { schema } from "lib/ample-editor/schema"
import { omitDefaultNodeAttributes } from "lib/ample-util/default-node-attributes"
import fuzzyMatch from "lib/ample-util/fuzzy-match"
import { DAILY_JOTS_TAG_TEXT, dailyNoteNameFromDate } from "lib/ample-util/jots"
import { newNoteParamsFromURL, noteParamsFromURL, urlFromNoteParams } from "lib/ample-util/note-url"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"
import LocalFileStore from "local-file-store"
import snackbarQueue from "snackbar-queue"

// --------------------------------------------------------------------------
// 100x100 yellow PNG, from http://png-pixel.com/
const DUMMY_IMAGE_DATA_URL = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAnElEQVR42u3RAQ0AAAgDIN8/9K3hHFQg7XQ4I0KEIEQIQoQgRAhChAgRghAhCBGCECEIEYIQhAhBiBCECEGIEIQgRAhChCBECEKEIAQhQhAiBCFCECIEIQgRghAhCBGCECEIQYgQhAhBiBCECEEIQoQgRAhChCBECEIQIgQhQhAiBCFCEIIQIQgRghAhCBGCECFChCBECEKEIOS7BY+0K0hTkKf9AAAAAElFTkSuQmCC";

// --------------------------------------------------------------------------
const DEFAULT_CONTENT = {
  type: "doc",
  content: [
    {
      type: "bullet_list_item",
      attrs: { collapsed: true, indent: 0 },
      content: [ { type: "paragraph", content: [ { type: "text", text: "One" } ] } ],
    },
    {
      type: "bullet_list_item",
      attrs: { indent: 1 },
      content: [ { type: "paragraph", content: [ { type: "text", text: "Two" } ] } ],
    },
    {
      type: "bullet_list_item",
      attrs: { indent: 0 },
      content: [ { type: "paragraph" } ],
    },
  ]
};

// --------------------------------------------------------------------------
const NOTES = [
  {
    name: "Some note",
    remoteUUID: uuidv4(),
    tags: [ { text: "blah" }, { text: "some-tag1", color: "f5624c" } ]
  },
  {
    name: "Blah whatever note",
    remoteUUID: uuidv4(),
    tags: [ { text: "zbaz/subtag", color: "ff0000" }, { text: "default-tag" }, { text: "daily-jots" } ],
  },
  {
    name: "Blahz",
    remoteUUID: uuidv4(),
    tags: [],
  }
];

// --------------------------------------------------------------------------
function addHeadingsFromNode(headings, node) {
  if (!node) return;

  if (!node.content) return;

  if (node.type === "heading") {
    if (node.content.length > 0) {
      headings.push(node);
    }
    return;
  }

  for (let index = 0; index < node.content.length; index++) {
    const childNode = node.content[index];
    addHeadingsFromNode(headings, childNode);
  }
}

// --------------------------------------------------------------------------
function applyNoteContentActions(noteParams, _noteContentActions) {
  snackbarQueue.notify({
    body: `Applying to ${ JSON.stringify(noteParams) }`,
    title: "applyNoteContentActions",
  });
}

// --------------------------------------------------------------------------
async function fetchNoteContent(noteURL, referenceType = null) {
  await new Promise(resolve => setTimeout(resolve, 500));

  if (referenceType === NOTE_CONTENT_TYPE.HEADINGS) {
    const headings = [];

    let note;
    if (noteURL === "#") {
      const documentContent = JSON.parse(localStorage.getItem("content")) || DEFAULT_CONTENT;
      addHeadingsFromNode(headings, documentContent);
      note = { name: "current note", url: "https://www.amplenote.com/notes/current-1" };
    } else {
      for (let i = 0; i < 100; i++) {
        headings.push({
          type: "heading",
          attrs: { level: 1 + i % 3 },
          content: [ { type: "text", text: `Heading ${ i }` } ]
        })
      }
      note = { name: "Some note", url: "https://www.amplenote.com/notes/some-1" };
    }

    return { headings, note };
  } else if (referenceType === NOTE_CONTENT_TYPE.LINKED) {
    // Return linked references to the currently loaded note in the note identified by noteURL

    if (noteURL === "https://www.amplenote.com/notes/local-321") {
      return [
        {
          node: {
            type: "doc",
            content: [
              {
                type: "bullet_list_item",
                attrs: { indent: 0 },
                content: [
                  {
                    type: "paragraph",
                    content: [
                      { type: "text", text: "Well, that's " },
                      {
                        type: "link",
                        attrs: { href: noteURL },
                        content: [ { type: "text", text: "another note" } ],
                        marks: [ { type: "strikethrough" } ],
                      },
                      { type: "text", text: " done today" }
                    ]
                  },
                ]
              },
              {
                type: "bullet_list_item",
                attrs: { indent: 1 },
                content: [
                  { type: "paragraph", content: [ { type: "text", text: "Sub-item was cool, but then blah blah blah" } ] }
                ]
              },
              {
                type: "bullet_list_item",
                attrs: { indent: 1 },
                content: [ { type: "paragraph", content: [ { type: "text", text: "another item here" } ] } ]
              }
            ]
          },
          text: "another note",
          updateTextOptions: {
            noteContentActions: [],
            noteParams: { remoteUUID: "123" },
          }
        },
      ];
    } else if (noteURL === "https://www.amplenote.com/notes/456") {
      // This is an "empty" reference - it doesn't contain any non-whitespace content outside the link itself
      return [
        {
          node: {
            type: "doc",
            content: [
              {
                type: "heading",
                content: [
                  {
                    type: "link",
                    attrs: { description: "", href: noteURL, media: null },
                    content: [ { type: "text", text: "some note link" } ]
                  },
                  { type: "text", text: " " },
                ]
              },
              { type: "paragraph", content: [ { type: "text", text: " " } ] },
            ]
          },
          text: "some note link",
        },
      ];
    } else {
      return [
        {
          node: {
            type: "paragraph",
            content: [
              { type: "text", text: "asd " },
              {
                type: "link",
                attrs: { description: "", href: noteURL, media: null },
                content: [ { type: "text", text: "blah blah" } ]
              },
              { type: "text", text: " " },
              { type: "text", marks: [ { type: "em" } ], text: "blah" },
              { type: "text", text: " and " },
              { type: "text", marks: [ { type: "strong" } ], text: "blah with " },
              { type: "text", marks: [ { type: "code" } ], text: "blah too" },
              { type: "text", text: " eh?" }
            ]
          },
          text: "blah blah",
        },
        {
          node: {
            type: "paragraph",
            content: [
              { type: "text", text: "well then, here's " },
              {
                type: "link",
                attrs: { description: "", href: noteURL, media: null },
                content: [ { type: "text", text: "some note link" } ]
              },
              { type: "text", text: " and " },
              {
                type: "link",
                attrs: { description: "", href: "https://www.amplenote.com/notes/456", media: null },
                content: [ { type: "text", text: "link to note about things" } ]
              },
            ]
          },
          text: "some note link",
        },
        {
          node: {
            type: "check_list_item",
            content: [
              {
                type: "paragraph",
                content: [
                  { type: "text", text: "well then, here's " },
                  {
                    type: "link",
                    attrs: { description: "", href: "https://www.amplenote.com/notes/456", media: null },
                    content: [ { type: "text", text: "blah" } ]
                  },
                ]
              },
            ]
          },
          text: "blah",
        },
      ];
    }

  } else if (referenceType === NOTE_CONTENT_TYPE.UNLINKED) {
    // Return unlinked references to the currently loaded note in the note identified by noteURL
    return [
      {
        node: {
          type: "paragraph",
          content: [
            { type: "text", text: "asd " },
            {
              type: "link",
              attrs: { description: "", href: noteURL, media: null },
              content: [ { type: "text", text: "blah blah" } ]
            },
            { type: "text", text: " " },
            { type: "text", marks: [ { type: "em" } ], text: "blah" },
            { type: "text", text: " " },
            { type: "text", text: "some note", marks: [ { type: "highlight" } ] },
            { type: "text", text: " and " },
            { type: "text", marks: [ { type: "strong" } ], text: "blah with " },
            { type: "text", marks: [ { type: "code" } ], text: "blah too" },
            { type: "text", text: " eh?" }
          ]
        },
        convertToLink: () => {
          console.log("converting to a link");
        }
      },
      {
        node: {
          type: "paragraph",
          content: [
            { type: "text", text: "well then, here's " },
            { type: "text", text: "some note", marks: [ { type: "highlight" } ] },
            { type: "text", text: " or whatever" },
          ]
        },
        convertToLink: () => {
          console.log("converting to a link");
        }
      },
    ];
  } else {
    // Return the content of the note identified by noteURL
    return {
      icon: "description",
      name: "Some great note",
      // node: JSON.parse(localStorage.getItem("content")) || DEFAULT_CONTENT,
      // Document with a centered image with a caption and banner image styling
      node: {
        type: "doc",
        attrs: { storage: { backgroundColor: "#bbeeff", bannerImageURL: "https://images.amplenote.com/075b89d4-176b-11ed-86a6-5e3d8aa45f69/0531cd2e-c27a-4ad4-a4c1-dd94f0cd9430.jpg" }},
        content: [{"type":"paragraph","content":[{"type":"image","attrs":{"align":"center","src":"https://images.amplenote.com/075b89d4-176b-11ed-86a6-5e3d8aa45f69/0531cd2e-c27a-4ad4-a4c1-dd94f0cd9430.jpg","text":"","width":null},"content":[{"type":"paragraph","content":[{"type":"text","text":"blah this is a caption"}]}]}]}]
      },
      tags: [
        { color: "ff0000", text: "some-tag" },
        { color: "00ff00", text: "other-tag" },
      ],
    };
  }
}

// --------------------------------------------------------------------------
async function fetchReferencedNotes(_localUUID, _remoteUUID) {
  return {};
}

// --------------------------------------------------------------------------
async function fetchReferencingNotes(_noteURL, { search = false } = {}) {
  await new Promise(resolve => setTimeout(resolve, 500));

  if (search) {
    return {
      referencedNote: { remoteUUID: "123", name: "blah" },
      referencingNotes: [
        {
          ...NOTES[0],
          url: urlFromNoteParams(NOTES[0]),
        },
        { url: "https://www.amplenote.com/notes/local-321", name: "Another note" },
      ]
    };
  } else {
    return {
      referencedNote: { remoteUUID: "123", name: "blah" },
      referencingNotes: [
        {
          ...NOTES[0],
          url: urlFromNoteParams(NOTES[0]),
        },
        { url: "https://www.amplenote.com/notes/local-321", name: "A local note", iconName: "archive" },
        { url: "https://www.amplenote.com/notes/456", name: "Note about things" },
      ],
    };
  }
}

// --------------------------------------------------------------------------
async function fetchTask(taskUUID) {
  return {
    content: [ { type: "paragraph", content: [ { type: "text", text: "some task" } ] } ],
    note: {
      name: "some note",
      remoteUUID: "remote-1",
    },
    remoteUUID: "remote-1",
    uuid: taskUUID,
  };
}

// --------------------------------------------------------------------------
function getIndexingStatus() {
  return { indexedCount: 99, notesCount: 100 };
}

// --------------------------------------------------------------------------
function linkNote(url, { content = null, tagTexts = [] } = {}) {
  const newNoteParams = newNoteParamsFromURL(url);

  let body = `Linking ${ newNoteParams ? "new" : "existing" } note`;
  if (tagTexts && tagTexts.length > 0) body += ` with tags ${ JSON.stringify(tagTexts) }`;
  if (content) body += ` with content ${ JSON.stringify(content) }`;

  snackbarQueue.notify({ body, title: "linkNote" });

  return newNoteParams ? `https://www.amplenote.com/notes/${ uuidv4() }` : url;
}

// --------------------------------------------------------------------------
function logContent() {
  console.log(localStorage.getItem("content"));
}

// --------------------------------------------------------------------------
function matchesAttribute(node, attributeName, expectedAttributeValue) {
  if (!expectedAttributeValue) return false;
  return node.attrs && node.attrs[attributeName] === expectedAttributeValue;
}

// --------------------------------------------------------------------------
function matchesLocalFileURL(node, attributeName, localFileURL) {
  if (!localFileURL) return false;
  if (!node.attrs) return false;

  const attributeValue = node.attrs[attributeName];
  return attributeValue && attributeValue.startsWith(localFileURL);
}

// --------------------------------------------------------------------------
function storeDocument(document) {
  const content = document.toJSON();
  const json = JSON.stringify(content);
  localStorage.setItem("content", json);

  // Micro-benchmarking test code for handling of document serialization
  // let packedNoteContent;
  // console.time("jsonpack");
  // {
  //   const { attrs, content: children, type, ...other } = content;
  //
  //   packedNoteContent = jsonpack.pack({
  //     // This order matches the order prosemirror's Node#toJSON produces
  //     type,
  //     attrs: omit(attrs, [ "serializedVersion" ]),
  //     content: children,
  //     ...other,
  //   });
  // }
  // console.timeEnd("jsonpack");
  //
  // console.time("omitDefaultAttributes");
  // omitDefaultNodeAttributes(content);
  // console.timeEnd("omitDefaultAttributes");
  //
  // let smallerPackedNoteContent;
  // console.time("jsonpack:smaller");
  // {
  //   const { attrs, content: children, type, ...other } = content;
  //
  //   smallerPackedNoteContent = jsonpack.pack({
  //     // This order matches the order prosemirror's Node#toJSON produces
  //     type,
  //     attrs: omit(attrs, [ "serializedVersion" ]),
  //     content: children,
  //     ...other,
  //   });
  // }
  // console.timeEnd("jsonpack:smaller");
  //
  // console.log("sizes",
  //   json.length, packedNoteContent.length,
  //   JSON.stringify(content).length, smallerPackedNoteContent.length
  // );
}

// --------------------------------------------------------------------------
function prettyLogContent() {
  console.log(JSON.stringify(JSON.parse(localStorage.getItem("content")), null, 2));
}

// --------------------------------------------------------------------------
function suggestNotes(value) {
  const notes = [];

  if (value.length > 0) {
    NOTES.forEach(note => {
      const { name, remoteUUID } = note;

      const match = fuzzyMatch(value, name, "<b>", "</b>")
      if (match) {
        notes.push({
          ...note,
          html: match.rendered,
          url: `https://www.amplenote.com/notes/${ remoteUUID }`,
        });
      }
    });

    const dailyJotNoteName = dailyNoteNameFromDate(new Date());
    const dailyJotMatch = fuzzyMatch(value, `Daily Jot: ${ dailyJotNoteName }`, "<b>", "</b>");
    if (dailyJotMatch) {
      notes.push({
        name: dailyJotNoteName,
        isTodayDailyNote: true,
        isUnpersistedDailyNote: true,
        html: dailyJotMatch.rendered,
        tags: [ { text: DAILY_JOTS_TAG_TEXT } ]
      });
    }
  }

  return {
    filterTags: [ { text: "daily-jots" } ],
    sourceTags: [ { text: "some-tag" } ],
    notes,
  };
}

// --------------------------------------------------------------------------
function suggestTags(value) {
  if (value.length === 0) return [];

  const tags = [];

  NOTES.forEach(note => {
    (note.tags || []).forEach(({ color, text }) => {
      const match = fuzzyMatch(value, text, "<b>", "</b>");
      if (match) tags.push({ color, html: match.rendered, text });
    });
  });

  return tags;
}

// --------------------------------------------------------------------------
function suggestTasks(text) {
  if (!text) return [];

  return [
    {
      match: `some <b>${ text }</b> task`,
      note: { name: "some note", remoteUUID: "remote-123" },
      task: { uuid: "task-123" },
      text: `some ${ text } task`,
    },
  ];
}

// --------------------------------------------------------------------------
function updateFileText(content, localFileURL, fileURL, text) {
  if (!content) return false;

  let changed = false;

  if (content.type === "image" || content.type === "video") {
    if (matchesAttribute(content, "src", fileURL)) {
      content.attrs.text = text;
      changed = true;
    } else if (matchesLocalFileURL(content, "src", localFileURL)) {
      if (fileURL) content.attrs.src = fileURL;
      content.attrs.text = text;
      changed = true;
    }
  } else if (content.type === "attachment") {
    if (matchesAttribute(content, "data", fileURL)) {
      content.attrs.text = text;
      changed = true;
    } else if (matchesLocalFileURL(content, "data", localFileURL)) {
      if (fileURL) content.attrs.data = fileURL;
      content.attrs.text = text;
      changed = true;
    }
  } else if (content.type === "link") {
    const { media } = content.attrs || {};
    if (media) {
      if (fileURL && media.url === fileURL) {
        content.attrs.media.text = text;
        changed = true;
      } else if (localFileURL && media.url === localFileURL) {
        if (fileURL) content.attrs.media.url = fileURL;
        content.attrs.media.text = text;
        changed = true;
      }
    }
  }

  (content.content || []).forEach(child => {
    if (updateFileText(child, localFileURL, fileURL, text)) {
      changed = true;
    }
  });

  return changed;
}

// --------------------------------------------------------------------------
export default class AmpleEditorDemo extends React.PureComponent {
  static propTypes = {
    onDocumentChange: PropTypes.func,
  };

  // --------------------------------------------------------------------------
  _editorRef = React.createRef();
  _editorView = null;
  _hostApp = null;
  _localFileStore = null;

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    let content = JSON.parse(localStorage.getItem("content"))

    // Request additional local storage, but only after we know we have something persisted (some browsers won't prompt
    // if you have nothing persisted).
    if (content && navigator.storage && navigator.storage.persist) {
      navigator.storage.persist();
    }

    if (!content) content = DEFAULT_CONTENT;

    let document = null;
    try {
      document = Node.fromJSON(schema, content);
    } catch (error) {
      if (error instanceof RangeError) {
        console.error(error);
        document = Node.fromJSON(schema, DEFAULT_CONTENT);
      } else {
        throw error;
      }
    }

    this.state = {
      initialDocument: document,
      editorTab: JSON.parse(localStorage.getItem("editorTab") || "null"),
      localFiles: LocalFileStore.loadLocalFiles(),
      readonly: false,
    };

    this._localFileStore = new LocalFileStore(
      () => this.state.localFiles,
      localFiles => { this.setState({ localFiles }); },
    );

    this._hostApp = {
      applyNoteContentActions,
      cloneNodes: this._cloneNodes,
      fetchNoteContent,
      fetchReferencedNotes,
      fetchReferencingNotes,
      fetchTask,
      getIndexingStatus,
      getPluginActions: pluginActionTypes => {
        const pluginActions = [];

        if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.IMAGE_OPTION)) {
          pluginActions.push({
            icon: "extension",
            name: "Test Plugin",
            run: async (editorContext, { caption, src, text }) => {
              console.log("running image option action", src, text, caption);
              editorContext.updateImage({ caption: "**test**" });
            },
            type: PLUGIN_ACTION_TYPE.IMAGE_OPTION,
          });
        }

        if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.INSERT_TEXT)) {
          pluginActions.push({
            icon: "auto_fix_normal",
            name: "Test Plugin",
            run: async editorContext => {
              await new Promise(resolve => setTimeout(resolve, 5000));
              return "this is the worst text\nIt has a newline now\n\nBleh";
            },
            type: PLUGIN_ACTION_TYPE.INSERT_TEXT,
          });

          pluginActions.push({
            checkResult: "Check result text",
            icon: "auto_fix_normal",
            name: "Test Plugin",
            run: async editorContext => {
              await new Promise(resolve => setTimeout(resolve, 5000));
              return "this is the worst text\nIt has a newline now\n\nBleh";
            },
            type: PLUGIN_ACTION_TYPE.INSERT_TEXT,
          });
        }

        if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.LINK_OPTION)) {
          pluginActions.push({
            icon: "extension",
            name: "Test Plugin",
            run: async (editorContext, { description, href }) => {
              console.log("running link option action", description, href);
              await new Promise(resolve => setTimeout(resolve, 5000));
              editorContext.updateLink({ description: description + "\n\n**test**" });
            },
            type: PLUGIN_ACTION_TYPE.LINK_OPTION,
          });
        }

        if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.REPLACE_TEXT)) {
          pluginActions.push({
            icon: "extension",
            name: "Test Plugin",
            run: async (editorContext, text) => {
              await new Promise(resolve => setTimeout(resolve, 5000));
              return `${ text } is the worst text\nIt has a newline now\n\nBleh`;
            },
            type: PLUGIN_ACTION_TYPE.REPLACE_TEXT,
          });

          pluginActions.push({
            checkResult: "Check Result Name",
            icon: "extension",
            name: "Test Plugin",
            run: async (editorContext, text) => {
              await new Promise(resolve => setTimeout(resolve, 5000));
              return `${ text } is the worst text\nIt has a newline now\n\nBleh`;
            },
            type: PLUGIN_ACTION_TYPE.REPLACE_TEXT,
          });
        }

        if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.TASK_OPTION)) {
          pluginActions.push({
            checkResult: "Check Result Name",
            icon: "extension",
            name: "Test Plugin",
            run: async (editorContext, task) => {
              console.log("running task option action", editorContext, task);
            },
            type: PLUGIN_ACTION_TYPE.TASK_OPTION,
          });
        }

        return pluginActions.map(pluginAction => Promise.resolve(pluginAction));
      },
      linkNote,
      openAttachment,
      openNoteLink: this._openNoteLink,
      startAttachmentUpload: this._startAttachmentUpload,
      // selectMedia: this._selectMedia,
      startMediaUpload: this._startMediaUpload,
      suggestNotes,
      suggestTags,
      suggestTasks,
    };

    const { onDocumentChange } = props;
    if (onDocumentChange) onDocumentChange(document);
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    const { localFiles } = this.state;

    Object.keys(localFiles).forEach(localFileUUID => {
      console.log("resuming local file upload", localFileUUID, localFiles[localFileUUID]);
      const { dataURL, kind } = localFiles[localFileUUID];

      // Need to convert data-url back to a Blob to upload
      fetch(dataURL)
        .then(response => response.blob())
        .then(blob => {
          if (kind === "attachment") {
            this._uploadLocalAttachment(localFileUUID, blob);
          } else if (kind === "media") {
            this._uploadLocalMedia(localFileUUID, blob);
          }
        })
        .catch(error => {
          console.error("Swallowing error attempting to resume file upload:", error);
        });
    });
  }

  // --------------------------------------------------------------------------
  render() {
    const { editorTab, initialDocument, localFiles, readonly } = this.state;
    if (initialDocument === null) return null;

    const localFileCount = Object.keys(localFiles).length;

    return (
      <React.Fragment>
        <AmpleEditor
          // --------------------------------------------------------------------------
          // Commented out props are provided as examples/common test scenarios
          // --------------------------------------------------------------------------
          document={ initialDocument }
          experimentalFeaturesEnabled
          // hideSelectionMenu
          // hideToolbar
          hostApp={ this._hostApp }
          // initialClassName="mobile-embed"
          initialEditorTab={ editorTab }
          // Need to re-mount when readonly changes, matching ample-web behavior
          key={ readonly ? "readonly" : "editable" }
          // interactiveHeadingAnchors
          // noEditorTabs
          onDocumentChange={ this._onDocumentChange }
          onInitialized={ this._onInitialized }
          onEditorTabChange={ this._onEditorTabChange }
          onEditorViewCreated={ this._onEditorViewCreated }
          onSelectionChange={ this._onSelectionChange }
          onTransactionDispatched={ this._onTransactionDispatched }
          placeholder="Enter some rich text..."
          readonly={ this.state.readonly }
          ref={ this._editorRef }
          tableOfContentsEnabled
          taskCompletionEffectLevelEm="normal"
        />

        <div className="debug-actions-row">
          <div>
            <a onClick={ this._resetDocument }>Reset doc</a> |&nbsp;
            <a onClick={ this._populateCompletedTasks }>Populate completed tasks</a> |&nbsp;
            <a onClick={ this._highlightListItem }>Highlight list item</a>
          </div>
          <span>
            { localFileCount } local file{ localFileCount === 1 ? "" : "s" } - <a
            onClick={ this._localFileStore.removeAll }>clear</a>
          </span>
        </div>
        <div className="debug-actions-row">
          <div>
            <a onClick={ logContent }>Log doc content</a> (<a onClick={ prettyLogContent }>formatted</a>) |&nbsp;
            <a onClick={ this._toggleReadonly }>Toggle readonly</a>
          </div>
          <span>
            tab: { (editorTab && editorTab.name) || "<null>" }{ (editorTab && !editorTab.expanded) ? " (collapsed)" : "" } - <a
            onClick={ this._clearEditorTab }>clear</a>
          </span>
        </div>
        <div className="debug-actions-row">
          <FindInEditor editorRef={ this._editorRef } />
        </div>
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  replaceDocument = newDocument => {
    const { current: ampleEditor } = this._editorRef;
    if (!ampleEditor) return false;

    ampleEditor.replaceDocument(newDocument);

    const { onDocumentChange } = this.props;
    if (onDocumentChange) onDocumentChange(newDocument);

    return true;
  };

  // --------------------------------------------------------------------------
  _clearEditorTab = () => {
    this.setState({ editorTab: null });
    localStorage.removeItem("editorTab");
  };

  // --------------------------------------------------------------------------
  _cloneNodes = (nodes, noteURL, { move = false } = {}) => {
    snackbarQueue.notify({
      title: "cloneNodes",
      body: `${ move ? "Moving" : "Copying" } ${ nodes.length } task${ nodes.length === 1 ? "" : "s" } to ${ noteURL }`,
    });

    const { current: editor } = this._editorRef;
    if (move && editor) {
      const uuids = nodes.map(({ attrs: { uuid } }) => uuid).filter(uuid => !!uuid);
      if (uuids.length) editor.removeNodes(uuids);
    }
  };

  // --------------------------------------------------------------------------
  _highlightListItem = () => {
    const { current: editor } = this._editorRef;
    editor.highlightListItem("5ad066a4-13c7-4740-a521-7e22c92c47be");
  };

  // --------------------------------------------------------------------------
  _onDocumentChange = document => {
    storeDocument(document);

    const { onDocumentChange } = this.props;
    if (onDocumentChange) onDocumentChange(document);
  };

  // --------------------------------------------------------------------------
  _onEditorTabChange = editorTab => {
    localStorage.setItem("editorTab", JSON.stringify(editorTab));
    this.setState({ editorTab });
  };

  // --------------------------------------------------------------------------
  _onEditorViewCreated = view => {
    // Applying this again on a hot reload will result in an error
    if (!this._editorView) applyDevTools(view);

    this._editorView = view;

    const fragment = window.location.hash.substr(1);
    if (fragment.length > 0) {
      const anchor = view.dom.querySelector(`a[name="${ fragment }"]`);
      if (anchor) anchor.scrollIntoView();
    }
  };

  // --------------------------------------------------------------------------
  _onInitialized = editor => {
    const { localFiles } = this.state;

    const urlByUUID = {};
    Object.keys(localFiles).forEach(uuid  => {
      const { dataURL, kind, objectURL } = localFiles[uuid];
      if (kind === "media") urlByUUID[uuid] = objectURL || dataURL
    });
    editor.replaceLocalFileURLs(urlByUUID);
  };

  // --------------------------------------------------------------------------
  _onSelectionChange(selection) {
    // console.log("selection change", selection.toJSON())
  }

  // --------------------------------------------------------------------------
  _onTransactionDispatched() {
    // console.log("onTransactionDispatched")
  }

  // --------------------------------------------------------------------------
  _openNoteLink = url => {
    const noteParams = noteParamsFromURL(url);
    console.log("OPENING NOTE LINK", noteParams || url);

    if (noteParams) {
      const { current: editor } = this._editorRef;
      if (editor && editor.hasFocus() && noteParams.fragment) {
        editor.setSelectionAfterHeading(noteParams.fragment);
      }
    }
  };

  // --------------------------------------------------------------------------
  _populateCompletedTasks = () => {
    const documentContent = cloneDeep(this._editorView.state.doc.toJSON());
    const { attrs: { completedTasks } } = documentContent;

    const now = Date.now();
    for (let dayOffset = 1; dayOffset < 400; dayOffset ++) {
      const checkedAtDate = subDays(now, dayOffset);
      const createdAtDate = subDays(checkedAtDate, 1);

      completedTasks.unshift({
        uuid: uuidv4(),
        createdAt: Math.floor(createdAtDate.getTime() / 1000),
        checkedAt: Math.floor(checkedAtDate.getTime() / 1000),
        p: [
          { type: "text", text: `Completed on ${ format(checkedAtDate, "yyyy/LL/dd") }` }
        ],
        value: 1 + Math.floor(Math.random() * 10),
      });
    }

    const { current: editor } = this._editorRef;
    editor.replaceDocument(Node.fromJSON(schema, documentContent));
  };

  // --------------------------------------------------------------------------
  _resetDocument = () => {
    // It can be convenient to generate a large or specific document here, e.g.:
    //
    // const bigDocContent = {
    //   "type": "doc",
    //   "content": []
    // };
    // for (let i = 0; i < 100; i++) {
    //   bigDocContent.content.push({
    //     type: "bullet_list_item",
    //     attrs: { collapsed: Math.random() > 0.8, indent: Math.floor(4 * Math.random()) },
    //     content: [ { type: "paragraph", content: [ { type: "text", text: `Item ${ i + 1 }` } ] } ],
    //   })
    // }
    //
    const { current: editor } = this._editorRef;
    editor.replaceDocument(Node.fromJSON(schema, DEFAULT_CONTENT));
  };

  // --------------------------------------------------------------------------
  _selectMedia = async callback => {
    // Since we're just stubbing in a dummy file, this mediaSelectUUID can be constant

    const response = await fetch(DUMMY_IMAGE_DATA_URL);
    const file = await response.blob();

    const localFileUUID = callback(file.type);
    this._startMediaUpload(localFileUUID, file);
  };

  // --------------------------------------------------------------------------
  _startAttachmentUpload = (uuid, file, _pos) => {
    this._localFileStore.store(uuid, file, "attachment", null).then(() => {
      return this._uploadLocalAttachment(uuid, file);
    });
  };

  // --------------------------------------------------------------------------
  _startMediaUpload = (localFileUUID, file) => {
    const { current: editor } = this._editorRef;

    const objectURL = URL.createObjectURL(file);
    editor.replaceLocalFileURLs({ [localFileUUID]: objectURL });

    this._localFileStore.store(localFileUUID, file, "media", objectURL).then(() => {
      return this._uploadLocalMedia(localFileUUID, file);
    });
  };

  // --------------------------------------------------------------------------
  _toggleReadonly = () => {
    const newReadonly = !this.state.readonly;
    this.setState({ readonly: newReadonly });
    snackbarQueue.notify({ title: newReadonly ? "Now readonly" : "Now editable" });
  };

  // --------------------------------------------------------------------------
  _uploadLocalAttachment = (localFileUUID, file) => {
    const { current: editor } = this._editorRef;

    return uploadAttachment(file).then(url => {
      editor.replaceLocalFileURLs({ [localFileUUID]: url });

      this._localFileStore.remove(localFileUUID);
    }).catch(error => {
      console.log("uploadLocalAttachment failed");
      console.error(error);
      editor.replaceLocalFileURLs({ [localFileUUID]: `local://${ localFileUUID }?failed` });
    });
  };

  // --------------------------------------------------------------------------
  async _uploadLocalMedia(localFileUUID, file) {
    const { current: editor } = this._editorRef;

    try {
      const imageInfo = await probeImage(file);
      const url = await uploadMedia(file, imageInfo ? imageInfo.mime : null);
      editor.replaceLocalFileURLs({ [localFileUUID]: url });
      this._localFileStore.remove(localFileUUID);

      // Uncomment to simulate OCR being applied to the image 2 seconds after upload completes
      // setTimeout(
      //   () => {
      //     const oldDocumentContent = cloneDeep(this._editorView.state.doc.toJSON());
      //     if (updateFileText(oldDocumentContent, `local://${ localFileUUID }`, url, "OCR TEST")) {
      //       const newDocument = Node.fromJSON(schema, oldDocumentContent);
      //       editor.replaceDocument(newDocument);
      //       storeDocument(newDocument);
      //
      //       console.log("UPDATED OCR TEXT");
      //     } else {
      //       console.error("FAILED TO UPDATE OCR TEXT")
      //     }
      //   },
      //   2000
      // );
    } catch (error) {
      console.log(`uploadLocalMedia failed with "${ error.message || error.toString() }"`);
      console.error(error);
      editor.replaceLocalFileURLs({ [localFileUUID]: `local://${ localFileUUID }?failed` });
    }
  }
}
