import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useAsyncEffect } from "@react-hook/async"
import Dropzone from "react-dropzone"
import { Button } from "@rmwc/button"
import { CircularProgress } from "@rmwc/circular-progress"
import { IconButton } from "@rmwc/icon-button"
import { TextField } from "@rmwc/textfield"

import DescriptionEditor from "lib/ample-editor/components/rich-footnote/description-editor"
import HrefIcon, { HREF_TYPE, hrefTypeFromHref } from "lib/ample-editor/components/rich-footnote/href-icon"
import HrefInput from "lib/ample-editor/components/rich-footnote/href-input"
import HrefPreview from "lib/ample-editor/components/rich-footnote/href-preview"
import NotePreview from "lib/ample-editor/components/rich-footnote/note-preview"
import TaskPreview from "lib/ample-editor/components/rich-footnote/task-preview"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { isIOS } from "lib/ample-editor/lib/client-info"
import { hasModKeyOnly } from "lib/ample-editor/lib/event-util"
import { filterImagesFromPaste, filterVideosFromPaste } from "lib/ample-editor/lib/handle-paste-files"
import { hrefFromAttribute, mediaFromURL, pluginParamsFromURL, targetFromURL } from "lib/ample-editor/lib/link-util"
import { isFailedLocalFileURL, isLocalFileURL } from "lib/ample-editor/plugins/file-plugin"

// --------------------------------------------------------------------------
const ACCEPTED_CONTENT_TYPES = "image/bmp,image/gif,image/jpeg,image/jpg,image/png,image/webp,video/mp4,video/mpeg,video/quicktime,video/webm";

// --------------------------------------------------------------------------
const FIELD_NAME = {
  DESCRIPTION: "description",
  HREF: "href",
  MEDIA: "media",
};

// --------------------------------------------------------------------------
function autoFocusFieldFromEditFieldName(description, editFieldName) {
  switch (editFieldName) {
    case "description": return FIELD_NAME.DESCRIPTION;
    case "href": return FIELD_NAME.HREF;
    case "media": return FIELD_NAME.MEDIA;
    default:
      return description.length > 0 ? FIELD_NAME.DESCRIPTION : FIELD_NAME.HREF;
  }
}

// --------------------------------------------------------------------------
function BottomActions({ allowEditing, close, edit, getLinkOptionPluginActions, readonly }) {
  const shouldShowActions = allowEditing && readonly;

  const { status: pluginActionsStatus, value: pluginActions } = useAsyncEffect(
    () => shouldShowActions ? getLinkOptionPluginActions() : Promise.resolve([]),
    // Note that we intentionally don't depend on `getLinkOptionPluginActions`, as it might get rebound more
    // frequently than we'd like, but we know that rebinding doesn't matter to the output
    [ shouldShowActions ]
  );

  // Note that the actions have class names so events can be attached to them by code outside the ample-editor project
  const actions = [];
  if (shouldShowActions) {
    actions.push(<Button className="edit-details-button" key="edit" onClick={ edit }>Edit details</Button>);

    (pluginActions || []).forEach(pluginAction => {
      actions.push(<PluginActionButton key={ pluginAction.uuid } pluginAction={ pluginAction } />);
    });
  }

  return (
    <div className="section bottom-container">
      <div className="bottom-container-actions">
        { actions }
        {
          shouldShowActions && pluginActionsStatus === "loading"
            ? (<CircularProgress className="plugins-loading-indicator" size={ 12 } />)
            : null
        }
      </div>

      <Button className="close-button" onClick={ close }>
        { (shouldShowActions || !allowEditing) ? "Close" : "Done" }
      </Button>
    </div>
  );
}

// --------------------------------------------------------------------------
function HrefPreviewWithHostAppContext({ adjustSpacer, href, nonBlankLinkTarget }) {
  // The RichFootnote can be rendered - e.g. in a public note - such that it doesn't have the
  // interface to the host app that is necessary to show private content
  const hostAppContext = useContext(HostAppContext);
  if (hostAppContext) {
    const hrefType = hrefTypeFromHref(href);
    if (hrefType === HREF_TYPE.NOTE) {
      return (<NotePreview nonBlankLinkTarget={ nonBlankLinkTarget } noteHref={ href } />);
    } else if (hrefType === HREF_TYPE.TASK) {
      return (<TaskPreview taskHref={ href } />);
    }
  }

  return (<HrefPreview href={ href } onLoad={ adjustSpacer } />);
}

// --------------------------------------------------------------------------
function HrefView({ close, href, nonBlankLinkTarget, runLinkTargetPluginActions }) {
  // If the link is to an in-page target, don't open it in a new window
  const targetDestination = targetFromURL(href, nonBlankLinkTarget);
  const linkHref = hrefFromAttribute(href);

  const onClick = useCallback(
    event => {
      const pluginParams = pluginParamsFromURL(href);
      if (pluginParams) {
        event.preventDefault();
        runLinkTargetPluginActions(pluginParams);
        close();
      } else if (href && href.length > 0) {
        close();
      } else {
        event.preventDefault();
      }
    },
    [ close, href, runLinkTargetPluginActions ]
  );

  return (
    <div className="section href-view">
      <HrefIcon
        close={ close }
        href={ href }
        linkHref={ linkHref }
        nonBlankLinkTarget={ nonBlankLinkTarget }
        runLinkTargetPluginActions={ runLinkTargetPluginActions }
      />
      <a
        className="href-link"
        href={ linkHref }
        target={ targetDestination }
        rel="noopener noreferrer"
        tabIndex="-1"
        onClick={ onClick }
      >
        { href }
      </a>
    </div>
  );
}

// --------------------------------------------------------------------------
function PluginActionButton({ pluginAction }) {
  const { icon, name, run } = pluginAction;

  const [ isRunning, setIsRunning ] = useState(false);

  // If the user closes the Rich Footnote while running, we don't want to
  // try and update state once a slow plugin finishes running
  const isUnmountedRef = useRef(false);
  useEffect(() => () => { isUnmountedRef.current = true; }, []);

  const onClick = useCallback(
    async () => {
      setIsRunning(true)
      try {
        await run();
      } finally {
        if (!isUnmountedRef.current) setIsRunning(false);
      }
    },
    [ run ]
  );

  return (
    <Button
      className="plugin-button body-text"
      disabled={ isRunning }
      icon={ icon }
      label={ name }
      onClick={ onClick }
      trailingIcon={ isRunning ? (<CircularProgress className="plugin-running-indicator" size={ 12 } />) : null }
    />
  );
}

// --------------------------------------------------------------------------
function renderMediaIndicator(failed, uploading) {
  if (failed) {
    return (
      <React.Fragment>
        <i className="material-icons local-file-indicator failed">error</i>
        <div className="uploading-indicator failed">
          <span className="uploading-indicator-text">Upload failed</span>
        </div>
      </React.Fragment>
    );
  }

  if (uploading) {
    return (
      <React.Fragment>
        <i className="material-icons local-file-indicator">cloud_upload</i>
        <div className="uploading-indicator">
          <CircularProgress size="medium" />
          <span className="uploading-indicator-text">Uploading...</span>
        </div>
      </React.Fragment>
    );
  }

  return null;
}

// --------------------------------------------------------------------------
function renderMediaURL(url, type, editable) {
  const isLocalFile = isLocalFileURL(url);
  if (isLocalFile) return null;

  if (type === "video") {
    // Hack from https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/ to force Mobile
    // Safari (and as of Safari 14 on macOS 10.15, desktop Safari too) to show the first frame of a video (which
    // it won't otherwise show until the user taps on the video, even with preload="metadata") by advancing a single
    // millisecond into the video.
    if (!url.includes("#t=")) url = `${ url }#t=0.001`;

    return (
      <video controls={ !editable } playsInline preload="metadata">
        <source src={ url } />
      </video>
    );
  } else {
    return (<img src={ url } alt="" />);
  }
}

// --------------------------------------------------------------------------
export default class RichFootnote extends React.Component {
  static propTypes = {
    // Link node attributes
    description: PropTypes.any,
    href: PropTypes.string,
    media: PropTypes.object,
    text: PropTypes.string,

    // Used in place of `media`, if provided
    localMediaURL: PropTypes.string,

    // Callbacks
    attachMediaFile: PropTypes.func,
    getLinkOptionPluginActions: PropTypes.func,
    onAttrChange: PropTypes.func,
    onClose: PropTypes.func,
    onTextChange: PropTypes.func,
    registerEditorView: PropTypes.func,
    runLinkTargetPluginActions: PropTypes.func,
    selectMedia: PropTypes.func,
    suggestNotes: PropTypes.func,

    // Settings
    adjustSpacer: PropTypes.func,
    allowEditing: PropTypes.bool,
    editFieldName: PropTypes.any,
    findIndex: PropTypes.number,
    findQuery: PropTypes.string,
    imageTextHasMatch: PropTypes.bool,
    imageTextIsCurrentMatch: PropTypes.bool,
    initialAllowAutoFocus: PropTypes.bool,
    initialHasContent: PropTypes.bool,
    initialIsNewLink: PropTypes.bool,
    nonBlankLinkTarget: PropTypes.string,
  };

  // --------------------------------------------------------------------------
  _descriptionEditorRef = React.createRef();
  _dropZoneRef = React.createRef();
  _hrefInputRef = React.createRef();
  _mediaButtonRef = React.createRef();
  _textInputRef = React.createRef();

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    const {
      allowEditing,
      description,
      editFieldName,
      href,
      initialAllowAutoFocus,
      initialHasContent,
      initialIsNewLink,
      media,
    } = props;

    let shouldAutoFocus = initialAllowAutoFocus && (initialIsNewLink || editFieldName);
    const editText = allowEditing && !initialHasContent;

    // In readonly mode, a link must be activated to edit the details
    let readonly = !allowEditing ||
      (!(initialIsNewLink || shouldAutoFocus) && (
        (description !== null && description.length > 0) ||
        (href !== null && href.length > 0) ||
        media !== null
      ));

    // The caller can request immediate editing of a specific field
    if (allowEditing && editFieldName) {
      if (readonly) {
        readonly = false;
      } else if (!shouldAutoFocus) {
        shouldAutoFocus = true;
      }
    }

    this.state = {
      // This may be changed later (based on props)
      autoFocusField: shouldAutoFocus ? autoFocusFieldFromEditFieldName(description, editFieldName) : null,
      editText,
      readonly,
      // Whether the user is in the process of selecting an image/video (when props.selectLocalFile is provided)
      selectingMediaFile: false,
      shouldAutoFocus,
      // We care about whether it _was_ a new link when opened, not after some amount of editing
      wasNewLink: initialIsNewLink,
    };
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    const { adjustSpacer, findIndex, findQuery } = this.props;

    if (adjustSpacer) adjustSpacer();

    if (findQuery) {
      const { current: descriptionEditor } = this._descriptionEditorRef;
      if (descriptionEditor) descriptionEditor.find(findQuery, null, { currentIndex: findIndex });
    }
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    const { adjustSpacer, allowEditing, editFieldName, findIndex, findQuery } = this.props;

    if (adjustSpacer) adjustSpacer();

    if (!prevProps.editFieldName && editFieldName !== prevProps.editFieldName && allowEditing) {
      if (this.state.readonly) {
        this._edit();
      } else {
        this._focus();
      }
    }

    if (findQuery !== prevProps.findQuery || findIndex !== prevProps.findIndex) {
      const { current: descriptionEditor } = this._descriptionEditorRef;
      if (descriptionEditor) descriptionEditor.find(findQuery, null, { currentIndex: findIndex });
    }
  }

  // --------------------------------------------------------------------------
  render() {
    const { allowEditing, getLinkOptionPluginActions } = this.props;
    const { readonly } = this.state;

    return (
      <div className="rich-footnote mdc-elevation--z2" onPaste={ this._handlePaste }>
        { this._renderTextInput() }
        { this._renderHref() }
        { this._renderDescription() }
        { this._renderMedia() }
        <BottomActions
          allowEditing={ allowEditing }
          close={ this._close}
          edit={ this._edit }
          getLinkOptionPluginActions={ getLinkOptionPluginActions }
          readonly={ readonly }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _attachMediaFile = file => {
    const { attachMediaFile, onTextChange, text } = this.props;
    const { wasNewLink } = this.state;

    if (!attachMediaFile) return;

    attachMediaFile(file);

    // Blank links will get removed from the document (eventually, once RF is closed) - we don't want the user to
    // lose their uploaded image, so at least need to add a space
    if (wasNewLink && !text) {
      onTextChange(" ");
    }
  };

  // --------------------------------------------------------------------------
  _canAttachMediaFiles = () => {
    return !!this.props.attachMediaFile;
  };

  // --------------------------------------------------------------------------
  _canSelectMedia = () => {
    return !!this.props.selectMedia;
  };

  // --------------------------------------------------------------------------
  _cancel = () => {
    if (this.props.onClose) {
      // The argument we're passing is whether the link should be removed, which we only want to do if it was a newly
      // created link.
      this.props.onClose(this.state.wasNewLink, false);
    }
  };

  // --------------------------------------------------------------------------
  _close = () => {
    if (this.props.onClose) {
      this.props.onClose(false, this.state.wasNewLink);
    }
  };

  // --------------------------------------------------------------------------
  _edit = () => {
    const { description, editFieldName } = this.props;

    const autoFocusField = autoFocusFieldFromEditFieldName(description, editFieldName);

    this.setState({ autoFocusField, readonly: false });
  };

  // --------------------------------------------------------------------------
  _focus() {
    const { current: textInput } = this._textInputRef;
    if (textInput) {
      // Text field always wins, as nothing else can be edited when it's empty
      textInput.focus();
    } else {
      const { description, editFieldName } = this.props;

      switch (autoFocusFieldFromEditFieldName(description, editFieldName)) {
        case FIELD_NAME.DESCRIPTION: {
          const { current: descriptionEditor } = this._descriptionEditorRef;
          if (descriptionEditor) descriptionEditor.focus();
          break;
        }

        default:
        case FIELD_NAME.HREF: {
          const { current: hrefInput } = this._hrefInputRef;
          if (hrefInput) hrefInput.focus();
          break;
        }

        case FIELD_NAME.MEDIA: {
          const { current: mediaButton } = this._mediaButtonRef;
          if (mediaButton) mediaButton.focus();
          break;
        }
      }
    }
  }

  // --------------------------------------------------------------------------
  _handleDrop = files => {
    if (!this._canAttachMediaFiles() || !files || files.length === 0) return;

    this._attachMediaFile(files[0]);
  };

  // --------------------------------------------------------------------------
  _handleHrefChange = href => {
    this.props.onAttrChange({ href });
  };

  // --------------------------------------------------------------------------
  _handleInputKeyDown = event => {
    switch (event.key) {
      case "Escape":
        event.preventDefault();
        event.stopPropagation();
        this._cancel();
        break;

      case "k":
        if (hasModKeyOnly(event.shiftKey)) {
          const { current: descriptionEditor } = this._descriptionEditorRef;
          if (!descriptionEditor || !descriptionEditor.isFocused()) {
            event.preventDefault();
            event.stopPropagation();
            this._close();
          }
        }
        break;

      case "Tab":
        if (event.shiftKey) {
          const { current: hrefInput } = this._hrefInputRef;
          const { current: textInput } = this._textInputRef;

          // Prevent shift-tab from the first input focusing something else on the page
          if (textInput) {
            if (event.target === textInput) {
              event.preventDefault();
              event.stopPropagation();
              this._close();
            }
          } else if (hrefInput && event.target === hrefInput) {
            event.preventDefault();
            event.stopPropagation();
            this._close();
          }
        } else {
          const { current: descriptionEditor } = this._descriptionEditorRef;
          const { current: hrefInput } = this._hrefInputRef;
          const { current: textInput } = this._textInputRef;

          // Prevent tab from the last input focusing something else on the page
          if (descriptionEditor && descriptionEditor.contains(event.target)) {
            event.preventDefault();
            event.stopPropagation();
            this._close();
          } else if (hrefInput && event.target === hrefInput) {
            // Need to manually focus the description editor, since it's not a normal input
            event.preventDefault();
            event.stopPropagation();
            if (descriptionEditor) descriptionEditor.focus();
          } else if (textInput && event.target === textInput) {
            if (this.state.editText && this.props.text.length === 0) {
              event.preventDefault();
              event.stopPropagation();
              this._cancel();
            }
          }
        }
        break;

      default:
        break;
    }
  };

  // --------------------------------------------------------------------------
  _handleInputKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      this._close();
    }
  };

  // --------------------------------------------------------------------------
  _handlePaste = event => {
    if (!this._canAttachMediaFiles()) return;

    const images = filterImagesFromPaste(event);
    const videos = filterVideosFromPaste(event);
    if (images.length === 0 && videos.length === 0) {
      // They may have copied an image from the body of the note, in which case there'll be some HTML being pasted
      // which we can try to parse the image out of
      try {
        const hasHtml = event.clipboardData && event.clipboardData.types &&
          event.clipboardData.types.includes && event.clipboardData.types.includes("text/html");

        if (!hasHtml) return;

        const dom = document.createElement("div");
        dom.innerHTML = event.clipboardData.getData("text/html");
        const img = dom.querySelector("img[src]");
        if (!img) return;

        event.preventDefault();
        event.stopPropagation();

        if (this.state.readonly) return;

        this.props.onAttrChange({ media: mediaFromURL(img.getAttribute("src")) });
      } catch (_error) {
        // This is a best-guess effort, it's fine if any part of it fails, particularly since browsers can
        // be fickle about their clipboard APIs
      }

      return;
    }

    event.preventDefault();
    event.stopPropagation();

    // Note that we want these _after_ preventing the paste in the outer editor
    if (this.state.readonly) return;

    if (images.length > 0) {
      this._attachMediaFile(images[0]);
    } else if (videos.length > 0) {
      this._attachMediaFile(videos[0]);
    }
  };

  // --------------------------------------------------------------------------
  _handleTextChange = event => {
    this.props.onTextChange(event.target.value);
  };

  // --------------------------------------------------------------------------
  // Unlike when the document changes, the outer editor has no idea that the selection has changed or the stored
  // marks have changed, but we want the toolbar state to update (e.g. highlighting buttons based on current cursor
  // position), which this will do by virtue of dispatching a transaction in the outer editor.
  _onDescriptionEditorChange = description => {
    const { onAttrChange } = this.props;

    if (description !== null) {
      onAttrChange({ description });
    } else {
      onAttrChange(null);
    }
  };

  // --------------------------------------------------------------------------
  _onHrefPasteClick = async () => {
    const { current: hrefInput } = this._hrefInputRef;
    if (hrefInput) hrefInput.focus();

    if (navigator.clipboard) {
      const href = await navigator.clipboard.readText();
      this.props.onAttrChange({ href });
    } else {
      // This works on iOS Safari, which doesn't implement the clipboard API
      document.execCommand("paste");
    }
  };

  // --------------------------------------------------------------------------
  _openFileDialog = () => {
    const { current: dropZone } = this._dropZoneRef;
    if (dropZone) dropZone.open();
  };

  // --------------------------------------------------------------------------
  _removeMedia = () => {
    this.props.onAttrChange({ media: null });
  };

  // --------------------------------------------------------------------------
  _renderDescription() {
    const { description, media, nonBlankLinkTarget, text } = this.props;

    if (this.state.readonly) {
      if (description && description.length > 0) {
        return (
          <div className="section">
            <DescriptionEditor
              key="description-editor-readonly"
              readonly
              ref={ this._descriptionEditorRef }
              value={ description }
            />
          </div>
        );
      }

      if (media) return null;

      const { adjustSpacer, href } = this.props;
      return href
        ? (
          <HrefPreviewWithHostAppContext
            adjustSpacer={ adjustSpacer }
            href={ href }
            nonBlankLinkTarget={ nonBlankLinkTarget }
          />
        )
        : null;
    }

    const { autoFocusField, editText } = this.state;

    return (
      <div className="section">
        <DescriptionEditor
          autoFocus={ autoFocusField === FIELD_NAME.DESCRIPTION && !editText }
          disabled={ editText && text.length === 0 }
          dispatchChanges={ this._onDescriptionEditorChange }
          key="description-editor"
          onKeyDown={ this._handleInputKeyDown }
          placeholder="Add detail or footnotes"
          ref={ this._descriptionEditorRef }
          registerEditorView={ this.props.registerEditorView }
          value={ description || "" }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderHref() {
    const { autoFocusField, editText, readonly } = this.state;
    const { allowEditing, href, nonBlankLinkTarget, runLinkTargetPluginActions, text } = this.props;

    let editable = allowEditing;
    if (readonly) {
      if (href && href.length > 0) {
        editable = false;
      } else {
        return null;
      }
    }

    if (!editable) {
      return (
        <HrefView
          close={ this._close }
          href={ href }
          nonBlankLinkTarget={ nonBlankLinkTarget }
          runLinkTargetPluginActions={ runLinkTargetPluginActions }
        />
      );
    }

    // Pasting via JS doesn't work great on modern browsers - nor does it seem to work at all in Android WebViews - so
    // we'll just show it on iOS, where it generally works.
    const icon = ((href && href.length > 0) || !isIOS)
      ? (
        <HrefIcon
          close={ this._close }
          href={ href }
          linkHref={ hrefFromAttribute(href) }
          nonBlankLinkTarget={ nonBlankLinkTarget }
          runLinkTargetPluginActions={ runLinkTargetPluginActions }
        />
      )
      : (
        <i
          className={ `href-icon${ text.length > 0 ? " valid" : "" }` }
          onClick={ text.length > 0 ? this._onHrefPasteClick : null }
        >content_paste</i>
      );

    const { suggestNotes } = this.props;

    return (
      <div className="section">
        <HrefInput
          autoFocus={ autoFocusField === FIELD_NAME.HREF && !editText }
          close={ this._close }
          disabled={ editText && text.length === 0 }
          icon={ icon }
          inputRef={ this._hrefInputRef }
          onChange={ this._handleHrefChange }
          onKeyPress={ this._handleInputKeyPress }
          onKeyDown={ this._handleInputKeyDown }
          placeholder={ editable ? "Enter a link or note title" : "No link specified" }
          suggestNotes={ suggestNotes }
          value={ href || "" }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderMedia() {
    const { imageTextHasMatch, imageTextIsCurrentMatch, localMediaURL, media, text } = this.props;

    const { type, url } = media || {};
    const isLocalFile = isLocalFileURL(url);
    const isFailedLocalFile = isFailedLocalFileURL(url);

    // localMediaURL isn't an actual attribute, but we'd prefer to display it if we have it (for local image support)
    const displayURL = localMediaURL || url;

    if (this.state.readonly) {
      if (!media) return null;

      let findMatchClass = "";
      if (imageTextIsCurrentMatch) {
        findMatchClass = "find-match current";
      } else if (imageTextHasMatch) {
        findMatchClass = "find-match";
      }

      return (
        <div className={ `media-view ${ findMatchClass } ${ isLocalFile ? "uploading" : "" }` }>
          { renderMediaURL(displayURL, type, isLocalFile) }

          {
            type !== "video"
              ? (
                <a className="media-link" href={ displayURL } target="_blank" rel="noopener noreferrer">
                  <div className="underlay" />
                  <i className="material-icons">open_in_new</i>
                </a>
              )
              : null
          }

          { renderMediaIndicator(isFailedLocalFile, isLocalFile) }
        </div>
      );
    }

    const { editText } = this.state;

    let sectionContent = null;

    if (media) {
      sectionContent = (
        <div className="media-edit">
          <a className="media-link" href={ displayURL } target="_blank" rel="noopener noreferrer">
            { renderMediaURL(displayURL, type, true) }
          </a>
          <IconButton className="media-delete-button" icon="delete" onClick={ this._removeMedia } />
        </div>
      );
    } else if (this._canSelectMedia()) {
      const { autoFocusField } = this.state;

      sectionContent = (
        <Button
          autoFocus={ autoFocusField === FIELD_NAME.MEDIA && !editText }
          disabled={ this.state.selectingMediaFile || (editText && text.length === 0) }
          onClick={ this._selectMedia }
          ref={ this._mediaButtonRef }
        >
          Add an image or video
        </Button>
      );
    } else if (this._canAttachMediaFiles()) {
      const { autoFocusField } = this.state;

      sectionContent = (
        <Dropzone
          accept={ ACCEPTED_CONTENT_TYPES }
          activeClassName="active"
          className="drop-zone"
          disableClick
          disablePreview
          multiple={ false }
          onDrop={ this._handleDrop }
          ref={ this._dropZoneRef }
        >
          <div className="drop-zone-inner">
            <div className="target inactive">
              <i className="material-icons">image</i>
              <span className="instructions">Drag an image here or</span>
              <Button
                autoFocus={ autoFocusField === FIELD_NAME.MEDIA && !editText }
                className="browse-files-button"
                onClick={ this._openFileDialog }
                ref={ this._mediaButtonRef }
              >
                browse files
              </Button>
            </div>
            <div className="target active">
              Drop image here.
            </div>
          </div>
        </Dropzone>
      );
    }

    if (sectionContent === null) return null;

    return (
      <div className={ `section media-input-section ${ isLocalFile ? "uploading" : "" }` }>
        { sectionContent }
        <label className="mdc-floating-label">{ type === "video" ? "Video" : "Image" }</label>
        { renderMediaIndicator(isFailedLocalFile, isLocalFile) }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderTextInput() {
    const { autoFocusField, editText } = this.state;
    if (!editText) return null;

    const { text } = this.props;

    return (
      <div className="section">
        <TextField
          // This takes precedence over any other field that can autofocus
          autoFocus={ !!autoFocusField }
          className="link-text-input"
          fullwidth
          inputRef={ this._textInputRef }
          placeholder="Enter the text that will appear in the note"
          onChange={ this._handleTextChange }
          onKeyPress={ this._handleInputKeyPress }
          onKeyDown={ this._handleInputKeyDown }
          value={ text || "" }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _selectMedia = async () => {
    if (!this._canSelectMedia() || this.state.selectingMediaFile) return;

    this.setState({ selectingMediaFile: true });
    try {
      await this.props.selectMedia();
    } finally {
      this.setState({ selectingMediaFile: false });
    }
  };
}
