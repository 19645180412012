import { upperFirst } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import { ListDivider, ListItem, ListItemGraphic, ListItemMeta, ListItemPrimaryText, ListItemText } from "@rmwc/list"

import { MAX_SUGGESTIONS } from "lib/ample-editor/components/link-target-menu/constants"
import NoteSuggestion from "lib/ample-editor/components/link-target-menu/note-suggestion"
import { keyFromTagSuggestion } from "lib/ample-editor/components/link-target-menu/tag-suggestions"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"

// --------------------------------------------------------------------------
const HEADING_ICON_PATHS = [
  VECTOR_ICON_PATHS["format-header-1"],
  VECTOR_ICON_PATHS["format-header-2"],
  VECTOR_ICON_PATHS["format-header-3"],
];

// --------------------------------------------------------------------------
function keyFromHeadingSuggestion(headingSuggestion) {
  const { headingText, index } = headingSuggestion;

  return `heading-${ headingText }-${ index }`;
}

// --------------------------------------------------------------------------
function HeadingSuggestion({ acceptHeadingSuggestion, headingSuggestion, isActiveSuggestion }) {
  const { headingHTML, headingLevel, headingText } = headingSuggestion;

  let icon;
  if (headingLevel < 0) {
    icon = "description";
  } else {
    const iconPath = HEADING_ICON_PATHS[headingLevel - 1];
    if (iconPath) {
      icon = (<svg viewBox="0 0 24 24"><path d={ iconPath }/></svg>);
    } else {
      icon = "title";
    }
  }

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptHeadingSuggestion(headingSuggestion);
    },
    [ headingSuggestion ]
  );

  return (
    <ListItem
      className="link-target-menu-item popup-list-item"
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      selected={ isActiveSuggestion }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ icon } />
      <ListItemText>
        <ListItemPrimaryText>
          { headingHTML ? <span dangerouslySetInnerHTML={ { __html: headingHTML } } /> : headingText }
        </ListItemPrimaryText>
      </ListItemText>
      { isActiveSuggestion ? <ListItemMeta icon="keyboard_return" /> : null }
    </ListItem>
  );
}

// --------------------------------------------------------------------------
export default function HeadingSuggestions(props) {
  const {
    acceptHeadingSuggestion,
    acceptNoteSuggestion,
    actionDescription,
    activeHeadingSuggestionIndex,
    activeNoteSuggestion,
    headingSuggestions,
    hideHeadingSuggestions,
    insertContentMode,
    loadingHeadingSuggestions,
  } = props;

  const onHideHeadingSuggestionsClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      hideHeadingSuggestions();
    },
    []
  );

  const renderedSuggestions = [];

  if (activeNoteSuggestion) {
    const { name } = activeNoteSuggestion;

    const noteSuggestion = {
      ...activeNoteSuggestion,
      text: (
        <span>
          {
            insertContentMode
              ? "Insert a section of"
              : `${ upperFirst(actionDescription || "link") } to a heading ${ name !== null ? "in" : "" }`
          } <span className="value">{ name }</span>
        </span>
      ),
    };

    renderedSuggestions.push(
      <NoteSuggestion
        acceptNoteSuggestion={ acceptNoteSuggestion }
        actionDescription={ actionDescription }
        isDisabled
        key={ keyFromTagSuggestion(noteSuggestion) }
        noteSuggestion={ noteSuggestion }
      />
    );

    renderedSuggestions.push(<ListDivider key="divider"/>);
  }

  if (loadingHeadingSuggestions) {
    renderedSuggestions.push(
      <ListItem disabled key="loading-headings">
        <ListItemGraphic icon={ <CircularProgress size="xsmall"/> } />
        Loading headings.
      </ListItem>
    );
  } else {
    let omittedCount = 0;

    headingSuggestions.forEach((headingSuggestion, index) => {
      if (index < MAX_SUGGESTIONS) {
        renderedSuggestions.push(
          <HeadingSuggestion
            acceptHeadingSuggestion={ acceptHeadingSuggestion }
            headingSuggestion={ headingSuggestion }
            isActiveSuggestion={ index === activeHeadingSuggestionIndex }
            key={ keyFromHeadingSuggestion(headingSuggestion) }
          />
        );
      } else {
        omittedCount++;
      }
    });

    if (headingSuggestions.length === 0) {
      renderedSuggestions.push(
        <ListItem disabled key="no-headings">
          No matching headings found.
        </ListItem>
      );

      renderedSuggestions.push(
        <ListItem
          className="link-target-menu-item popup-list-item"
          key="headings-cancel"
          onClick={ onHideHeadingSuggestionsClick }
          onMouseDown={ preventEventDefault }
          selected
          tabIndex="-1"
        >
          <ListItemGraphic icon="arrow_back" />
          <ListItemText>
            <ListItemPrimaryText>
              <span>Search for a note to { insertContentMode ? "insert" : "link" }</span>
            </ListItemPrimaryText>
          </ListItemText>
          <ListItemMeta icon={ <svg viewBox="0 0 24 24"><path d={ VECTOR_ICON_PATHS["keyboard-esc"] }/></svg> } />
        </ListItem>
      );
    } else if (omittedCount > 0) {
      renderedSuggestions.push(
        <ListItem disabled key="omitted-headings">
          Continue typing to search { omittedCount } heading{ omittedCount === 1 ? "" : "s" } not listed above.
        </ListItem>
      );
    }
  }

  return renderedSuggestions;
}

HeadingSuggestions.propTypes = {
  acceptHeadingSuggestion: PropTypes.func.isRequired,
  acceptNoteSuggestion: PropTypes.func.isRequired,
  actionDescription: PropTypes.string,
  activeHeadingSuggestionIndex: PropTypes.number,
  activeNoteSuggestion: PropTypes.object,
  headingSuggestions: PropTypes.array,
  hideHeadingSuggestions: PropTypes.func.isRequired,
  insertContentMode: PropTypes.bool,
  loadingHeadingSuggestions: PropTypes.bool,
};
