import { keyBy, upperFirst } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import {
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText
} from "@rmwc/list"
import Tippy from "@tippyjs/react"

import TagIcon from "lib/ample-editor/components/tag-icon"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"

// --------------------------------------------------------------------------
export function isNewNoteSuggestion(noteSuggestion) {
  return !noteSuggestion.url && !noteSuggestion.isUnpersistedDailyNote;
}

// --------------------------------------------------------------------------
export function keyFromNoteSuggestion(noteSuggestion) {
  const { name, url } = noteSuggestion;

  return url || `new-note-suggestion-${ name }`;
}

// --------------------------------------------------------------------------
function Tags({ matchingFilterTags, newTags, tags }) {
  if (matchingFilterTags.length === 0 && newTags.length === 0 && tags.length === 0) return null;

  const existingTagByText = keyBy(tags, "text");

  const matchingFilterTagsContent = (matchingFilterTags || []).map(({ text, ...tagMetadata }) => {
    delete existingTagByText[text];

    return (
      <div className="tag existing" key={ text }>
        <TagIcon { ...tagMetadata } />
        { text }
      </div>
    );
  });

  const newTagsContent = (newTags || []).map(({ canBeOmitted, match, text, ...tagMetadata }) => {
    delete existingTagByText[text];

    const newTagContent = (
      <div className="tag" key={ text }>
        <strong>+</strong><TagIcon { ...tagMetadata } />
        { match ? <span dangerouslySetInnerHTML={ { __html: match } }/> : text }
      </div>
    );

    if (canBeOmitted) {
      return (
        <Tippy
          content="Add ~ to the beginning of the link text to omit this tag"
          delay={ 200 }
          key={ text }
          placement="bottom"
          touch={ false }
        >
          { newTagContent }
        </Tippy>
      );
    } else {
      return newTagContent;
    }
  });

  const existingTagsContent = Object.values(existingTagByText).map(({ text, ...tagMetadata }) => (
    <div className="tag existing" key={ text }>
      <TagIcon { ...tagMetadata } />
      { text }
    </div>
  ));

  return (
    <ListItemSecondaryText>
      { newTagsContent }
      { matchingFilterTagsContent }
      { existingTagsContent }
    </ListItemSecondaryText>
  );
}

// --------------------------------------------------------------------------
export default function NoteSuggestion(props) {
  const { acceptNoteSuggestion, actionDescription, isActiveSuggestion, isDisabled, noteSuggestion } = props;

  const { html, matchingFilterTags, name, newTags, tags } = noteSuggestion;

  const isExistingNote = !isNewNoteSuggestion(noteSuggestion);

  let icon;
  let text;
  if (isExistingNote) {
    icon = noteSuggestion.icon || "description";
    text = noteSuggestion.text || (html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : name);
  } else {
    icon = "create";
    text = noteSuggestion.text || (
      <span>
        { upperFirst(actionDescription || "link") } to a new note named <span className="value">{ name }</span>
      </span>
    );
  }

  const { shares } = newTags.find(newTag => newTag.shares) || {};

  let sharedTagsWarning = null;
  if (shares) {
    sharedTagsWarning = (
      <ListItemSecondaryText className="shared-tag-warning">
        <i className="material-icons">error</i>
        <span className="text">
            Applying this tag will share the note with { shares } user{ shares === 1 ? "" : "s" }
          </span>
      </ListItemSecondaryText>
    )
  }

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptNoteSuggestion(noteSuggestion);
    },
    [ noteSuggestion ]
  );

  return (
    <ListItem
      className={ `link-target-menu-item popup-list-item ${ sharedTagsWarning ? "tall" : "" }` }
      disabled={ isDisabled }
      onClick={ isDisabled ? null : onClick }
      onMouseDown={ preventEventDefault }
      selected={ isActiveSuggestion }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ icon } />
      <ListItemText>
        <ListItemPrimaryText>
          { text }
        </ListItemPrimaryText>

        <Tags
          matchingFilterTags={ matchingFilterTags }
          newTags={ newTags }
          tags={ tags || [] }
        />

        { sharedTagsWarning }
      </ListItemText>
      { isActiveSuggestion ? <ListItemMeta icon="keyboard_return" /> : null }
    </ListItem>
  );
}

NoteSuggestion.propTypes = {
  acceptNoteSuggestion: PropTypes.func.isRequired,
  actionDescription: PropTypes.string,
  isActiveSuggestion: PropTypes.bool,
  isDisabled: PropTypes.bool,
  noteSuggestion: PropTypes.object.isRequired,
};
