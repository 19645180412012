import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useAsyncEffect } from "@react-hook/async"
import {
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText
} from "@rmwc/list"

import { MAX_SUGGESTIONS } from "lib/ample-editor/components/link-target-menu/constants"
import NoteSuggestion, { keyFromNoteSuggestion } from "lib/ample-editor/components/link-target-menu/note-suggestion"
import { hasModifierKey, preventEventDefault } from "lib/ample-editor/lib/event-util"
import parseNoteLinkText from "lib/ample-editor/lib/parse-note-link-text"
import { VectorIcon } from "lib/ample-editor/lib/vector-icon-paths"
import AMPLENOTE_AREA, { urlFromAmplenoteParams } from "lib/ample-util/amplenote-area"
import { formatDate } from "lib/ample-util/date"
import { TASK_RELATION, VECTOR_ICON_NAME_BY_TASK_RELATION } from "lib/ample-util/task-url"
import { iconNameFromPriority } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const ORDERED_TASK_RELATIONS = [
  TASK_RELATION.WAITING,
  TASK_RELATION.MIRRORING,
  TASK_RELATION.BENEFITING,
  TASK_RELATION.CONNECTED,
];

const DECRIPTION_BY_TASK_RELATION = {
  [TASK_RELATION.BENEFITING]: "Increase this task's score by linking to a task that would benefit from this task's completion",
  [TASK_RELATION.CONNECTED]: "Basic link to another task (no other effects)",
  [TASK_RELATION.MIRRORING]: "Create a copy of this task whose state (completed, hidden, deleted) will remain synced to the original",
  [TASK_RELATION.WAITING]: "Hide a task until this task is concluded",
};

const HINT_BY_TASK_RELATION = {
  [TASK_RELATION.BENEFITING]: "Search for a task that will benefit from completing this task",
  [TASK_RELATION.CONNECTED]: "Type to search for a task to link",
  [TASK_RELATION.MIRRORING]: "Type to search for a note to create a mirrored task in",
  [TASK_RELATION.WAITING]: "Type to search for a task to hide until this task is concluded",
};

const TITLE_BY_TASK_RELATION = {
  [TASK_RELATION.BENEFITING]: "beneficiary task",
  [TASK_RELATION.CONNECTED]: "connected task",
  [TASK_RELATION.MIRRORING]: "mirrored task",
  [TASK_RELATION.WAITING]: "waiting task",
};

// --------------------------------------------------------------------------
function keyFromTaskSuggestion(taskSuggestion) {
  return taskSuggestion.task.uuid;
}

// --------------------------------------------------------------------------
function useSelectedSuggestion(cancel, ref, selectSuggestion, selectSuggestionOnTab, suggestions) {
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  const length = suggestions ? suggestions.length : 0;

  // Clamp selected index when length changes
  useEffect(
    () => {
      setSelectedIndex(selectedIndexWas => Math.min(selectedIndexWas, Math.max(0, length - 1)));
    },
    [ length ]
  );

  const moveSelectedIndex = useCallback(
    delta => {
      setSelectedIndex(selectedIndexWas => {
        const lastIndex = Math.max(0, length - 1);

        let newSelectedIndex = selectedIndexWas + delta;

        if (newSelectedIndex < 0) {
          newSelectedIndex = Math.max(0, Math.min(length + newSelectedIndex, lastIndex));
        } else if (newSelectedIndex > lastIndex) {
          newSelectedIndex = Math.min(Math.max(0, newSelectedIndex - length), lastIndex);
        }

        return newSelectedIndex;
      });
    },
    [ length ]
  );

  useImperativeHandle(ref, () => ({
    handleKeyDown: event => {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case "ArrowDown":
        case "ArrowUp":
          if (!hasModifierKey(event)) {
            moveSelectedIndex(event.key === "ArrowDown" ? 1 : -1);
            return true;
          }
          break;

        case "Tab":
          if (!selectSuggestionOnTab) return true;
        // eslint-disable-next-line no-fallthrough
        case "Enter": {
          const selectedSuggestion = suggestions[selectedIndex];
          if (selectedSuggestion) selectSuggestion(selectedSuggestion);
          return true;
        }

        case "Escape":
          cancel();
          return true;
      }
    },
  }));

  return selectedIndex;
}

// --------------------------------------------------------------------------
function NoteTargetSuggestions({ cancel, close, mirrorTask, searchText, suggestNotes }, ref) {
  const { tags, text } = useMemo(() => parseNoteLinkText(searchText), [ searchText ]);

  const { value: noteSuggestions } = useAsyncEffect(
    async () => {
      const result = await suggestNotes(text, tags);
      if (!result) return null;

      return result.notes.slice(0, MAX_SUGGESTIONS).map(noteSuggestion => {
        // Matching what LinkTargetMenu does to massage suggestions
        return { ...noteSuggestion, matchingFilterTags: [], newTags: [] };
      });
    },
    [ tags, text ]
  );

  const selectSuggestion = noteSuggestion => {
    const { url: noteURL } = noteSuggestion;
    mirrorTask(noteURL);
    close();
  };

  const selectedIndex = useSelectedSuggestion(cancel, ref, selectSuggestion, false, noteSuggestions);

  if (noteSuggestions && noteSuggestions.length > 0) {
    return noteSuggestions.map((noteSuggestion, index) => {
      return (
        <NoteSuggestion
          acceptNoteSuggestion={ selectSuggestion }
          actionDescription="Mirror task"
          isActiveSuggestion={ selectedIndex === index }
          key={ keyFromNoteSuggestion(noteSuggestion) }
          noteSuggestion={ noteSuggestion }
        />
      );
    })
  } else {
    return (
      <ListItem disabled>
        { HINT_BY_TASK_RELATION[TASK_RELATION.MIRRORING] }
      </ListItem>
    );
  }
}
// eslint-disable-next-line no-func-assign
NoteTargetSuggestions = forwardRef(NoteTargetSuggestions);

// --------------------------------------------------------------------------
function TaskRelationSuggestion({ isSelected, selectSuggestion, taskRelation }) {
  const icon = (<VectorIcon name={ VECTOR_ICON_NAME_BY_TASK_RELATION[taskRelation] } />);

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      selectSuggestion(taskRelation);
    },
    [ taskRelation ]
  );

  return (
    <ListItem
      className="link-target-menu-item popup-list-item dynamic-height"
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      selected={ isSelected }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ icon } />

      <ListItemText>
        <ListItemPrimaryText className="title">
          { TITLE_BY_TASK_RELATION[taskRelation] }
        </ListItemPrimaryText>
        <ListItemSecondaryText className="description">
          { DECRIPTION_BY_TASK_RELATION[taskRelation] }
        </ListItemSecondaryText>
      </ListItemText>

      { isSelected ? (<ListItemMeta icon="keyboard_return" />) : null }
    </ListItem>
  );
}

// --------------------------------------------------------------------------
function TaskRelationSuggestions({ acceptSuggestionOnTab, cancel, setTaskRelation }, ref) {
  const selectSuggestion = taskRelation => {
    setTaskRelation(taskRelation);
  };

  const selectedIndex = useSelectedSuggestion(cancel, ref, selectSuggestion, acceptSuggestionOnTab, ORDERED_TASK_RELATIONS);

  const suggestions = ORDERED_TASK_RELATIONS.map((taskRelation, index) => {
    return (
      <TaskRelationSuggestion
        isSelected={ selectedIndex === index }
        key={ `task-relation-${ taskRelation }` }
        selectSuggestion={ selectSuggestion }
        taskRelation={ taskRelation }
      />
    );
  });

  return (
    <React.Fragment>
      <ListItem className="heading" disabled>
        Link a task
      </ListItem>

      { suggestions }
    </React.Fragment>
  );
}
// eslint-disable-next-line no-func-assign
TaskRelationSuggestions = forwardRef(TaskRelationSuggestions);

// --------------------------------------------------------------------------
function TaskTargetSuggestions(props, ref) {
  const { acceptSuggestion, acceptSuggestionOnTab, cancel, searchText, suggestTasks, taskRelation } = props;

  const { tags, text } = useMemo(() => parseNoteLinkText(searchText), [ searchText ]);

  const { value: taskSuggestions } = useAsyncEffect(
    async () => {
      const tasks = await suggestTasks(text);
      return tasks.slice(0, MAX_SUGGESTIONS);
    },
    [ tags, text ]
  );

  const selectSuggestion = taskSuggestion => {
    const url = urlFromAmplenoteParams({
      area: AMPLENOTE_AREA.TASKS,
      task: taskSuggestion.task,
      taskParams: { relation: taskRelation },
    });

    acceptSuggestion("task link", url, []);
  };

  const selectedIndex = useSelectedSuggestion(cancel, ref, selectSuggestion, acceptSuggestionOnTab, taskSuggestions);

  if (taskSuggestions && taskSuggestions.length > 0) {
    return taskSuggestions.map((taskSuggestion, index) => {
      return (
        <TaskSuggestion
          isSelected={ selectedIndex === index }
          key={ keyFromTaskSuggestion(taskSuggestion) }
          selectSuggestion={ selectSuggestion }
          taskSuggestion={ taskSuggestion }
        />
      )
    })
  } else {
    return (
      <ListItem disabled>
        { HINT_BY_TASK_RELATION[taskRelation] }
      </ListItem>
    );
  }
}
// eslint-disable-next-line no-func-assign
TaskTargetSuggestions = forwardRef(TaskTargetSuggestions);

// --------------------------------------------------------------------------
function TaskSuggestion({ isSelected, selectSuggestion, taskSuggestion }) {
  const { match: html, note, task, text } = taskSuggestion;

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      selectSuggestion(taskSuggestion);
    },
    [ taskSuggestion ]
  );

  const { due } = task;

  let dueAttribute = null;
  if (due) {
    dueAttribute = (
      <div className="attribute">
        <i className="material-icons due-date-icon">event_available</i>
        <span className="text">
          { formatDate(due * 1000, { short: true, strict: true }) }
        </span>
      </div>
    )
  }

  let priorityAttribute = null;
  const priorityIconName = iconNameFromPriority(task.flags);
  if (priorityIconName) {
    priorityAttribute = (
      <div className="attribute">
        <i className="material-icons priority-icon">{ priorityIconName }</i>
      </div>
    );
  }

  return (
    <ListItem
      className="link-target-menu-item popup-list-item dynamic-height"
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      selected={ isSelected }
      tabIndex="-1"
    >
      <ListItemGraphic icon="check_box_outline_blank" />

      <ListItemText>
        <ListItemPrimaryText>
          { html ? (<span dangerouslySetInnerHTML={ { __html: html } } />) : text }
        </ListItemPrimaryText>

        <ListItemSecondaryText>
          <div className="attribute">
            <i className="material-icons">{ note.icon || "description" }</i>
            <span className="text">{ note.name }</span>
          </div>
          { dueAttribute }
          { priorityAttribute }
        </ListItemSecondaryText>
      </ListItemText>
    </ListItem>
  );
}

// --------------------------------------------------------------------------
export default function TaskSuggestions(props, ref) {
  const {
    acceptSuggestion,
    acceptSuggestionOnTab,
    close,
    mirrorTask,
    searchText,
    suggestNotes,
    suggestTasks,
  } = props;

  const [ taskRelation, setTaskRelation ] = useState(null);

  const subMenuRef = useRef(null);

  useImperativeHandle(ref, () => ({
    handleKeyDown: event => {
      const { current: subMenu } = subMenuRef;
      return subMenu ? subMenu.handleKeyDown(event) : false;
    },
  }));

  const cancelTaskSuggestions = () => {
    setTaskRelation(null);
  };

  if (taskRelation === TASK_RELATION.MIRRORING) {
    return (
      <NoteTargetSuggestions
        cancel={ cancelTaskSuggestions }
        close={ close }
        mirrorTask={ mirrorTask }
        ref={ subMenuRef }
        searchText={ searchText }
        suggestNotes={ suggestNotes }
      />
    );
  } else if (taskRelation) {
    return (
      <TaskTargetSuggestions
        acceptSuggestion={ acceptSuggestion }
        acceptSuggestionOnTab={ acceptSuggestionOnTab }
        cancel={ cancelTaskSuggestions }
        ref={ subMenuRef }
        searchText={ searchText }
        suggestTasks={ suggestTasks }
        taskRelation={ taskRelation }
      />
    );
  } else {
    return (
      <TaskRelationSuggestions
        acceptSuggestionOnTab={ acceptSuggestionOnTab }
        cancel={ close }
        ref={ subMenuRef }
        setTaskRelation={ setTaskRelation }
      />
    );
  }
}

// eslint-disable-next-line no-func-assign
TaskSuggestions = forwardRef(TaskSuggestions);

TaskSuggestions.propTypes = {
  acceptSuggestion: PropTypes.func.isRequired,
  acceptSuggestionOnTab: PropTypes.bool,
  close: PropTypes.func.isRequired,
  mirrorTask: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  suggestNotes: PropTypes.func.isRequired,
  suggestTasks: PropTypes.func.isRequired,
};
